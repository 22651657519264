import React from "react";
import "../../../assets/styles/components/utils/Modals/aboutCambiumReadMore.scss";
import { useSelector, useDispatch} from "react-redux";
import { setModalAboutCambiumHeading } from "../../../assets/scripts/store/redux-slices/modals.js";
import gsap from "gsap";

function AboutCambiumReadMore({ lang }) {
  const dispatch = useDispatch();

  const [lastScrollPosition, setLastScrollPosition] = React.useState(0);
  const modalIsOpen = useSelector(
    (state) => state.modals.modalAboutCambiumHeading
  );


  const closeModal = () => {
    let tl = gsap.timeline({
      onComplete: () => {
        dispatch(setModalAboutCambiumHeading(false));
        window?.scrollTo(0, lastScrollPosition);
      },
    });
    tl.fromTo(
      ".bi-cambium-parallax-modal__content",
      { translateY: 0, opacity: 1 },
      { translateY: 16, opacity: 0 }
    );
    tl.fromTo(".bi-cambium-parallax-modal", { opacity: 1 }, { opacity: 0 });
    tl.fromTo(
      ".bi-cambium-parallax-modal-container",
      { opacity: 1 },
      { opacity: 0 },
      "-=0.25"
    );
  };

  const openModal = () => {
    let tl = gsap.timeline();

    tl.fromTo(
      ".bi-cambium-parallax-modal-container",
      { opacity: 0 },
      { opacity: 1 }
    );
    tl.fromTo(
      ".bi-cambium-parallax-modal",
      { opacity: 0 },
      { opacity: 1 },
      "-=0.25"
    );
    tl.fromTo(
      ".bi-cambium-parallax-modal__content",
      { translateY: 16, opacity: 0 },
      { translateY: 0, opacity: 1 }
    );
  };

  React.useEffect(() => {
    if (modalIsOpen) {
      setLastScrollPosition(window.scrollY);
      openModal();
    }
  }, [modalIsOpen]);

  if (modalIsOpen) {
    return (
      <div className="bi-cambium-parallax-modal-container">
        <div className="bi-cambium-parallax-modal">
          <div
            className="bi-cambium-parallax-modal__content"
            onWheel={(event) => {
              event.stopPropagation();
            }}
          >
            <button>
              <p
                onClick={() => {
                  closeModal();
                }}
                className="bi-secondary-paragraph"
              >
                {lang === "fr" ? "Retour" : "Back"}
              </p>
            </button>
            {lang === "fr" ? (
              <>
                {" "}
                <p className="bi-primary-paragraph">
                  J’ai commencé à investir en bourse un peu avant l’âge de 20
                  ans et j’ai préparé ensuite la création de Cambium pendant 15
                  ans, d’abord en apprenant ce qu’étaient un bilan et un compte
                  de résultat au sein des équipes Transaction Services du
                  cabinet EY, puis en apprenant le métier d’investisseur chez
                  l’un des principaux acteurs du capital investissement en
                  France, le groupe Siparex.
                </p>
                <p className="bi-secondary-paragraph">
                  Pendant cette période, j’ai pu constituer un track-record
                  personnel d’investissement en bourse, conservant durant toutes
                  ces années mes relevés de transactions, que j’ai pu faire
                  ensuite auditer par le cabinet Deloitte, afin d’attester de
                  mes performances et de m’aider dans ma première levée de
                  fonds. J’ai également pu observer la façon de travailler des
                  grands acteurs de la gestion d’actifs cotés et les pratiques
                  du marché, ce qui m’a permis de relever certaines incohérences
                  et d’imaginer une façon différente de faire ce métier. Une
                  fois prêt pour créer Cambium, j’ai eu la chance de pouvoir
                  m’associer avec le Groupe Siparex, qui a joué un rôle
                  d’incubateur et m’a aidé à lever les premiers millions d’euros
                  pour lancer la structure. Aujourd’hui Cambium permet à ses
                  souscripteurs d’investir dans la « vraie économie », selon une
                  méthode d’investissement inspirée du Private Equity, mais avec
                  les deux grands avantages de la bourse que sont la liquidité
                  et l’absence de montant minimum de souscription.
                </p>
              </>
            ) : (
              <>
                {" "}
                <p className="bi-primary-paragraph">
                  I started investing in the stock market a little before the
                  age of 20 and and then prepared the creation of Cambium for 15
                  years, first by learning years, first by learning what a
                  balance sheet and income statement were balance sheet and
                  income statement in the Transaction Services team of then by
                  learning the investor's job with one of the main players in
                  the one of the main players in private equity in France, the
                  Siparex the Siparex group.
                </p>
                <p className="bi-secondary-paragraph">
                  During this period, I was able to build up a personal
                  track-record of personal track record of investing in the
                  stock market, keeping all my transaction records, which I was
                  able to have audited by Deloitte audited by the firm Deloitte,
                  in order to certify my performance and my performance and to
                  help me in my first fund raising. fundraising. I was also able
                  to observe the way of working of the of the major players in
                  listed asset management and the practices of the practices,
                  which allowed me to identify some inconsistencies and to
                  imagine a and to imagine a different way of doing this
                  business. A ready to create Cambium, I was lucky enough to be
                  able to to partner with the Siparex Group, which acted as an
                  incubator incubator and helped me raise the first million
                  euros to launch the structure. to launch the structure. Today
                  Cambium allows its subscribers to subscribers to invest in the
                  "real economy", using an investment method investment method
                  inspired by private equity, but with the two the two major
                  advantages of the stock market, namely liquidity and the
                  absence of a minimum subscription amount.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AboutCambiumReadMore;
