import { Link } from "gatsby";
import React from "react";
import { createPortal } from "react-dom";
import "../assets/styles/components/footer.scss";
import BasicModal from "./utils/Modals/BasicModal";
import Buttons from "./utils/Buttons";
import scrollLock from "../assets/scripts/utils/scrollLock";
import FormNewsletter from "./utils/FormNewsletter";
import eventBus from "../assets/scripts/utils/eventBus";

import gsap from 'gsap';

function Footer() {
  const [lang, setLang] = React.useState("fr");
  const [legalPortal, setLegalPortal] = React.useState(false);
  const [leafletPortal, setLeafletPortal] = React.useState(false);

  //
  const [isAllreadyVisible, setAllReadyVisible] = React.useState();
  const section = React.useRef();
  //

  const introComponent = () => {
    let tlMaster = gsap.timeline();

    let tlTop = gsap.timeline();
    tlTop.fromTo(section.current.querySelectorAll('.footer-info-top div'), {opacity: 0, x:'-16px'}, {opacity: 1, x:0, duration: 0.6, stagger: 0.15, delay: 0.2})
    let tlBottom = gsap.timeline();
    tlBottom.fromTo(section.current.querySelectorAll('.footer-info-bottom p'), {opacity: 0, x:'-16px'}, {opacity: 1, x:0, duration: 0.6, stagger: 0.15})

    tlMaster.add(tlTop);
    tlMaster.add(tlBottom, '<50%');
  };

  React.useEffect(() => {
    if (legalPortal || leafletPortal) {
      scrollLock.enable();
    } else {
      scrollLock.disable();
    }
  }, [legalPortal, leafletPortal]);

  React.useEffect(() => {
    const footerObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          eventBus.dispatch("footerVisible");
          if(!isAllreadyVisible  && window.innerWidth > 768){
            introComponent();
            setAllReadyVisible(true);
          }
        } else {
          eventBus.dispatch("footerOut");
        }
      });
    });

    footerObserver.observe(document.querySelector("footer"));

    return () => {
      setLegalPortal(false);
      setLeafletPortal(false);
    };
  }, [isAllreadyVisible]);

  React.useEffect(() => {
    const checkLang = () => {
      if (window.location.pathname.includes("/en")) {
        setLang("en");
      } else {
        setLang("fr");
      }
    };

    checkLang();
    eventBus.on("routeChange", () => {
      checkLang();
    });
  }, []);
  return (
    <footer ref={section}>
      <div className="footer-info-top">
        <div className="footer-info__left">
          {lang === "fr" ? (
            <p className="bi-primary-paragraph">
              Inscrivez-vous à notre newsletter
            </p>
          ) : (
            <p className="bi-primary-paragraph">Subscribe to our newsletter</p>
          )}
          <FormNewsletter />
          <p className="bi-primary-paragraph footer-info__paragraph">
            <Link
              to={
                lang === "fr"
                  ? "/documents/newsletters"
                  : "/en/documents/newsletters"
              }
            >
              {lang === "fr"
                ? "Historique des newsletters"
                : "Newsletter history"}
            </Link>
          </p>
          <p className="bi-primary-paragraph footer-info__paragraph">
            {leafletPortal &&
              createPortal(
                <BasicModal
                  title={
                    lang === "fr"
                      ? "Informations réglementaires"
                      : "Regulatory information"
                  }
                  closeText={lang === "fr" ? "Je refuse" : "I refuse"}
                  hideModal={() => {
                    setLeafletPortal(false);
                  }}
                  acceptButton={
                    <Buttons
                      type="external-link"
                      to="https://www.quantalys.com/Fonds/2002089"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        setLeafletPortal(false);
                      }}
                    >
                      {lang === "fr" ? "J'accepte" : "I agree"}
                    </Buttons>
                  }
                >
                  {lang === "fr" ? (
                    <>
                      Ce site vous permet de consulter uniquement les
                      informations relatives aux Fonds qui font l’objet d’une
                      offre publique dans le pays sélectionné. Ce site Internet
                      ne s'adresse pas aux personnes relevant d'une juridiction
                      dans laquelle la publication du site Internet ou son accès
                      est interdit(e) en raison de leur nationalité ou de leur
                      lieu de résidence. Les personnes accédant au site Internet
                      savent qu'elles sont seules responsables du respect des
                      lois et réglementations applicables dans leur pays de
                      résidence et/ou de nationalité.
                      <br />
                      <br />
                      Avant tout investissement dans un Organisme de Placement
                      Collectif (« OPC »), qui par nature présente un risque de
                      perte du capital investi, nous vous invitons à consulter
                      un conseiller en investissement et à consulter le Document
                      d’Information Clé pour l’Investisseur (DICI) et le
                      prospectus, disponibles sur ce site.
                      <br />
                      <br />
                      <br />
                      <h4>Objectif du site</h4>
                      <br />
                      Ce site est un site d'information dont l'objectif est de
                      présenter l'activité de Cambium ainsi que les principales
                      caractéristiques de ses OPC et services. Ce site n’est pas
                      un site transactionnel. Toute information peut être
                      modifiée sans préavis par Cambium.
                      <br />
                      <br />
                      Ce site Internet n'est pas destiné aux citoyens ou
                      résidents des États-Unis d'Amérique ou à des «U.S.
                      Persons» tel que ce terme est défini dans le «Regulation
                      S» de la loi américaine de 1933 sur les valeurs
                      mobilières. Aucun Fonds présenté ici ne peut être proposé
                      ou vendu directement ou indirectement aux États-Unis
                      d'Amérique à des résidents et citoyens des États-Unis
                      d'Amérique et à des «U.S. Persons».
                      <br />
                      <br />
                      Veuillez noter que le fonds Multifund Midcap Value est
                      agréé uniquement au Luxembourg et est autorisé à la
                      commercialisation uniquement en France et au Luxembourg.
                      Les informations figurant sur la page présentant le fonds
                      ne visent pas à être distribuées ou utilisées par toute
                      personne ou entité dans un pays ou une juridiction où
                      cette distribution ou utilisation serait contraire aux
                      dispositions légales ou réglementaires, ou qui imposerait
                      à Cambium de se conformer aux obligations d’enregistrement
                      de ces pays ou juridictions.
                      <br />
                      <br />
                      L'accès à ces informations peut être régi, restreint ou
                      interdit par les lois ou réglementations qui vous sont
                      applicables, spécialement les lois du pays depuis lequel
                      vous visitez ce site. Cette page n'est pas destinée aux
                      personnes relevant de pays dans lesquels (en raison de la
                      nationalité des personnes, de leur lieu de résidence ou
                      pour toute autre raison) la diffusion ou l'accès à cette
                      page est interdit. Il vous appartient en tant que visiteur
                      de ce site de vous informer et de respecter toutes les
                      lois et réglementations qui vous sont applicables, et
                      ainsi de vous assurer, au préalable, que vous êtes
                      juridiquement autorisé à souscrire dans le véhicule
                      d’investissement.
                      <br />
                      <br />
                      En cliquant sur le bouton ci-dessous «Valider», vous
                      reconnaissez avoir lu et compris cette page, vous acceptez
                      de vous conformer à son contenu et vous confirmez que vous
                      accédez à ce site Internet conformément aux lois et
                      réglementations de la juridiction ou du pays dans lequel
                      vous résidez.
                    </>
                  ) : (
                    <>
                      This site allows you to consult only the information about
                      the Funds that are the subject of a public offering in the
                      selected the selected country. This website is not
                      intended for jurisdiction in which publication of or
                      access to the website is the publication of or access to
                      the website is prohibited by reason of nationality or
                      place of residence. The persons accessing the website are
                      aware that they are solely are solely responsible for
                      compliance with the laws and regulations applicable in
                      their country of residence and/or nationality.
                      <br />
                      <br />
                      Before any investment in a Collective Investment Scheme
                      ("CIS"), which by its nature presents a risk ("UCI"),
                      which by its very nature presents a risk of loss of the
                      loss of the invested capital, we invite you to consult an
                      investment advisor advisor and to consult the Key Investor
                      Information Document (KID) (DICI) and the prospectus,
                      available on this site.
                      <br />
                      <br />
                      <br />
                      <h4>Purpose of the website</h4>
                      <br />
                      This site is an information site whose objective is to to
                      present Cambium's activity as well as the main features of
                      its characteristics of its TPOs and services. This site is
                      not a transactional site. All information can be modified
                      without without notice by Cambium.
                      <br />
                      <br />
                      This website is not intended for citizens or residents of
                      the of the United States of America or "U.S. Persons" as
                      that term is defined in defined in Regulation S of the
                      U.S. Securities Act of 1933. Securities Act of 1933. No
                      Fund presented here may be offered or sold directly or
                      indirectly in the United States of America to United
                      States of America to residents and citizens of the United
                      States of America and to U.S. Persons.
                      <br />
                      <br />
                      Please note that the Multifund Midcap Value fund is only
                      authorised in Luxembourg only in Luxembourg and is
                      authorised for marketing only in France and Luxembourg
                      only. The information on the fund's page is not intended
                      for distribution is not intended for distribution to, or
                      use by, any person or or entity in any country or
                      jurisdiction where such distribution distribution or use
                      would be contrary to legal or regulatory or regulatory
                      requirements, or which would require Cambium to comply
                      with registration requirements of such countries or
                      jurisdictions. jurisdictions.
                      <br />
                      <br />
                      Access to this information may be governed, restricted or
                      prohibited prohibited by the laws or regulations
                      applicable to you, especially applicable to you,
                      especially the laws of the country from which you visit
                      this site. This page is not intended for persons countries
                      in which (by reason of the nationality of the nationality,
                      place of residence or for any other reason) the
                      distribution of or access to this page is prohibited. It
                      is It is your responsibility as a visitor to this site to
                      to inform yourself and to respect all laws and regulations
                      applicable to you, and thus to ensure, in advance, that
                      you are that you are legally entitled to subscribe to the
                      investment vehicle. in the investment vehicle.
                      <br />
                      <br />
                      By clicking on the button below "Validate", you
                      acknowledge that you have read and understood this page,
                      you agree to to comply with its contents and confirm that
                      you are accessing this accessing this website in
                      accordance with the laws and laws and regulations of the
                      jurisdiction or country in which you reside.
                    </>
                  )}
                </BasicModal>,
                document.querySelector(".bi-modal-portal")
              )}
            <a
              href="https://www.quantalys.com/Fonds/2002089"
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                e.preventDefault();
                setLeafletPortal(true);
              }}
            >
              {lang === "fr" ? "Valeur Liquidative" : "Net Asset Value (NAV)"}
            </a>
          </p>
          <p className="bi-primary-paragraph footer-info__paragraph">
            {legalPortal &&
              createPortal(
                <BasicModal
                  title={
                    lang === "fr"
                      ? "Informations réglementaires"
                      : "Regulatory information"
                  }
                  closeText={lang === "fr" ? "Je refuse" : "I refuse"}
                  hideModal={() => {
                    setLegalPortal(false);
                  }}
                  acceptButton={
                    <Buttons
                      type="link"
                      to={
                        lang === "fr"
                          ? "/documents/reglementaires?has_accepted=true"
                          : "/en/documents/reglementaires?has_accepted=true"
                      }
                    >
                      {lang === "fr" ? "J'accepte" : "I agree"}
                    </Buttons>
                  }
                >
                  {lang === "fr" ? (
                    <>
                      {" "}
                      Ce site vous permet de consulter uniquement les
                      informations relatives aux Fonds qui font l’objet d’une
                      offre publique dans le pays sélectionné. Ce site Internet
                      ne s'adresse pas aux personnes relevant d'une juridiction
                      dans laquelle la publication du site Internet ou son accès
                      est interdit(e) en raison de leur nationalité ou de leur
                      lieu de résidence. Les personnes accédant au site Internet
                      savent qu'elles sont seules responsables du respect des
                      lois et réglementations applicables dans leur pays de
                      résidence et/ou de nationalité.
                      <br />
                      <br />
                      Avant tout investissement dans un Organisme de Placement
                      Collectif (« OPC »), qui par nature présente un risque de
                      perte du capital investi, nous vous invitons à consulter
                      un conseiller en investissement et à consulter le Document
                      d’Information Clé pour l’Investisseur (DICI) et le
                      prospectus, disponibles sur ce site.
                    </>
                  ) : (
                    <>
                      This site allows you to consult only the information about
                      the Funds that are the subject of a public offering in the
                      selected the selected country. This website is not
                      intended for jurisdiction in which publication of or
                      access to the website is the publication of or access to
                      the website is prohibited by reason of nationality or
                      place of residence. The persons accessing the website are
                      aware that they are solely are solely responsible for
                      compliance with the laws and regulations applicable in
                      their country of residence and/or nationality.
                      <br />
                      <br />
                      Before any investment in a Collective Investment Scheme
                      ("CIS"), which by its nature presents a risk ("UCI"),
                      which by its very nature presents a risk of loss of the
                      loss of the invested capital, we invite you to consult an
                      investment advisor advisor and to consult the Key Investor
                      Information Document (KID) (DICI) and the prospectus,
                      available on this site.
                    </>
                  )}
                </BasicModal>,
                document.querySelector(".bi-modal-portal")
              )}
            <a
              href="/documents/reglementaires"
              onClick={(e) => {
                e.preventDefault();
                setLegalPortal(true);
              }}
            >
              {lang === "fr" ? "Documents réglementaires" : "Legals documents"}
            </a>
          </p>
        </div>
        <div className="footer-info__right">
          <div className="footer-info__right__content">
            <p className="bi-primary-paragraph">
              <Link to={lang === "fr" ? "/strategie" : "/en/strategie"}>
                {lang === "fr" ? "Stratégie" : "Strategy"}
              </Link>
            </p>
            <p className="bi-primary-paragraph">
              <Link to={lang === "fr" ? "/a-propos" : "/en/a-propos"}>
                {lang === "fr" ? "À propos" : "About us"}
              </Link>
            </p>
            <p className="bi-primary-paragraph">
              <Link
                to={
                  lang === "fr" ? "/comment-souscrire" : "/en/comment-souscrire"
                }
              >
                {lang === "fr" ? "Comment souscrire ?" : "How to invest ?"}
              </Link>
            </p>
            <p className="bi-primary-paragraph">
              <Link to={lang === "fr" ? "/faq" : "/en/faq"}>FAQ</Link>
            </p>
            <p className="bi-primary-paragraph">
              <Link to={lang === "fr" ? "/contact" : "/en/contact"}>
                Contact
              </Link>
            </p>
          </div>
          <div className="footer-info__right__social">
            <br />
            <Link
              to={(lang === "en" ? "/en" : "") + "/mentions-legales"}
              className="bi-secondary-paragraph"
            >
              {lang === "fr" ? "Mentions légales" : "Legal Notice"}
            </Link>{" "}
            <br /> <br />
            <a
              href="https://www.linkedin.com/company/cambium-asset-management/"
              target="_blank"
              rel="noreferrer"
              aria-label={lang === "fr" ? "Aller sur le Linkedin de Cambium" : "Go to Cambium's Linkedin"}
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 0C5.82075 0 0 5.82075 0 13C0 20.1793 5.82075 26 13 26C20.1793 26 26 20.1793 26 13C26 5.82075 20.1793 0 13 0ZM8.12067 5.85325C9.03283 5.85325 9.64058 6.461 9.64058 7.27133C9.64058 8.08167 9.03283 8.68942 8.01992 8.68942C7.10775 8.6905 6.5 8.08167 6.5 7.27133C6.5 6.461 7.10775 5.85325 8.12067 5.85325ZM9.75 18.4167H6.5V9.75H9.75V18.4167ZM20.5833 18.4167H17.524V13.6803C17.524 12.3706 16.7083 12.0683 16.4028 12.0683C16.0973 12.0683 15.0768 12.2698 15.0768 13.6803C15.0768 13.8818 15.0768 18.4167 15.0768 18.4167H11.9167V9.75H15.0778V10.959C15.4852 10.2538 16.3009 9.75 17.8306 9.75C19.3603 9.75 20.5833 10.959 20.5833 13.6803V18.4167Z"
                  fill="#1B1B1D"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-info-bottom">
        <div>
          <p className="bi-secondary-paragraph">
            © Cambium 2022 /{" "}
            <a href="https://www.siparex.com/" target="_blank" rel="noreferrer">
              Groupe Siparex
            </a>
          </p>
          <p className="bi-secondary-paragraph">
            {lang === "fr" ? "Réalisé par" : "Developed by"}{" "}
            <a href="https://bihua.fr/" target="_blank" rel="noreferrer">
              Bihua
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
