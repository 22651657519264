import React from "react";
import { Link } from "gatsby";
import "../../assets/styles/components/utils/buttons.scss";

import { useDispatch } from "react-redux";
import { setModalAboutCambiumHeading } from "../../assets/scripts/store/redux-slices/modals.js";

const Buttons = (props) => {
  const dispatch = useDispatch();
  // const test = useSelector((state) => state.modals.modalAboutCambiumHeading)

  switch (props.type) {
    case "external-link":
      return(
        <a className="bi-button" {...props} href={props.to}>
          {props.children}
        </a>
      )
      break;
    case "link":
      return (
        <Link to="/" className="bi-button" {...props}>
            {props.children}
        </Link>
      );
    case "read-more":
      return (
        <button
          onClick={() => {
            dispatch(setModalAboutCambiumHeading(true));
          }}
          className="bi-button bi-button-readMore bi-secondary-paragraph"
          {...props}
        >
          {props.children}
        </button>
      );

    case "submit":
      return (
        <button className="bi-button bi-button-submit bi-secondary-paragraph"  {...props}>
          {props.children}
        </button>
      );
    default:
      return (
        <Link to="/strategie" {...props}>
          {props.children}
        </Link>
      );
  }
}; 

export default Buttons;
