import {createSlice} from '@reduxjs/toolkit'
import scrollLock from '../../utils/scrollLock';

export const modalSlice = createSlice({
    name: 'modals',
    initialState: {
        modalAboutCambiumParallax : false,
        modalAboutCambiumHeading: false,
    },
    reducers : {
        setModalAboutCambiumParallax: (state, action) => {
            state.modalAboutCambiumParallax = action.payload;
            if(action.payload === true && typeof window === 'object'){
                scrollLock.enable();
            }
            else if(typeof window === 'object'){
                scrollLock.disable();
            }
          },
        setModalAboutCambiumHeading: (state, action) => {
            state.modalAboutCambiumHeading = action.payload;
            if(action.payload === true && typeof window === 'object'){
                scrollLock.enable();
            }
            else if(typeof window === 'object'){
                scrollLock.disable();
            }
        },
    }
})


export const {
    setModalAboutCambiumParallax,
    setModalAboutCambiumHeading,
} = modalSlice.actions;

export default modalSlice.reducer;