import React from "react";
import "../../../assets/styles/components/utils/Modals/basicCambiumModal.scss";
import gsap from "gsap";
import eventBus from "../../../assets/scripts/utils/eventBus";
import scrollLock from "../../../assets/scripts/utils/scrollLock";

const BasicModal = ({
  children,
  title,
  closeText,
  acceptButton,
  hideModal,
}) => {
  React.useEffect(() => {
    eventBus.on("routeChange", () => {
      hideModal();
      scrollLock.disable();
    });
  });

  React.useEffect(() => {
    let tl = gsap.timeline();
    tl.fromTo(".basic-modal-container", { opacity: 0 }, { opacity: 1 });
    tl.fromTo(
      ".basic-modal-container .basic-modal-content",
      { opacity: 0, translateY: 16 },
      { opacity: 1, translateY: 0 }
    );
  }, []);

  return (
    <div className="basic-modal-container">
      <div className="basic-modal-content">
        <h3 className="basic-modal-header">{title}</h3>
        <p
          className="basic-modal-body bi-primary-paragraph"
          onWheel={(event) => {
            event.stopPropagation();
          }}
        >
          {children}
        </p>
        <div className="basic-modal-actions">
          <ul>
            <li>
              <button
                onClick={() => {
                  let tl = gsap.timeline({
                    onComplete: ()=>{
                      scrollLock.disable();
                      hideModal();
                    }
                  });
                  tl.fromTo(
                    ".basic-modal-container .basic-modal-content",
                    { opacity: 1, translateY: 0 },
                    { opacity: 0, translateY: -16 }
                  );
                  tl.fromTo(".basic-modal-container", { opacity: 1 }, { opacity: 0 }, '-=0.3');
                }}
              >
                {closeText}
              </button>
            </li>
            <li>{acceptButton}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BasicModal;
