import React from "react";
import Lenis from "@studio-freight/lenis";

function useSmoothScroll() {
  const [lenis, setLenis] = React.useState(null);
  React.useLayoutEffect(() => {
    const scroll = () => {
      if (window.lenis) return window.lenis;
      const l = new Lenis({
        duration: 1.2,
        easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)), // https://easings.net/
        smooth: true,
        direction: "vertical",
        // wrapper: document.querySelector("body"), // element that has overflow
      });
      setLenis(l);
      const scrollFn = (time) => {
        l.raf(time);
        requestAnimationFrame(scrollFn);
      };
      requestAnimationFrame(scrollFn);
      window.lenis = l;
      return () => {
        lenis?.destroy();
      };
    }

    scroll()

  }, [lenis]);
  return lenis;
}
export default useSmoothScroll;
