import eventBus from './eventBus';

/* export default {
  enable(customFunc) {
    let $body =
      typeof document.querySelector("body") === "object"
        ? document.querySelector("body")
        : false;

    let scrollPosition = 0;

    if ($body) {
      eventBus.dispatch('scrollStop');
      scrollPosition = window && window.pageYOffset;
      $body.style.overflow = "hidden";
      $body.style.position = "fixed";
      $body.style.top = `-${scrollPosition}px`;
      $body.style.width = "100%";
    }
  },
  disable(customFunc) {
    let $body =
      typeof document.querySelector("body") === "object"
        ? document.querySelector("body")
        : false;

    let scrollPosition =
      document.querySelector("body").getBoundingClientRect().y * -1;

    if ($body) {
      $body.style.overflow = "hidden auto";
      $body.style.removeProperty("position");
      $body.style.removeProperty("top");
      $body.style.removeProperty("width");
      window && window?.scrollTo(0, scrollPosition);
      window?.scrollTo(0, scrollPosition);
      setTimeout(() => {
        eventBus.dispatch('scrollStart');
        window.dispatchEvent(new CustomEvent("scroll"));
      });
    }
  },
}; */


export default {
  enable(customFunc) {
      eventBus.dispatch('scrollStop');
  },
  disable(customFunc) {
        eventBus.dispatch('scrollStart');
    }
}; 