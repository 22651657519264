import React from "react";
import gsap from "gsap";
import eventBus from "../../../assets/scripts/utils/eventBus";
import imagesLoaded from "imagesloaded";

import "../../../assets/styles/components/modules/ScreenLoader.scss";

const ScreenLoader = () => {
  const [visibility, setVisibility] = React.useState(true);
  React.useEffect(() => {
    if (visibility) {
      let pathLengthAnim = document
        .querySelector(".bi-loader_wrapper-content > svg > path")
        .getTotalLength();

      let tl = gsap.timeline({
        onComplete: () => {
          setVisibility(false);
          eventBus.dispatch('siteLoaderAnimationEnd');
          eventBus.dispatch('scrollStart');
          window.dispatchEvent(new Event("resize"));
        },
      });

      tl.fromTo(".bi-loader_wrapper-content", { opacity: 0 }, { opacity: 1 });
      tl.fromTo(
        ".bi-loader_wrapper-content > svg:nth-child(2)",
        { translateY: -32 },
        { translateY: 0 }
      );
      tl.fromTo(
        ".bi-loader_wrapper-content > svg:nth-child(1)",
        { opacity: 0 },
        { opacity: 1 }
      );

      imagesLoaded('main', ()=>{
        tl.fromTo(
          ".bi-loader_wrapper-content > svg:nth-child(1) > path:nth-child(2)",
          { strokeDasharray: pathLengthAnim, strokeDashoffset: 0 },
          { strokeDasharray: pathLengthAnim, strokeDashoffset: pathLengthAnim, duration: 1 }
        );
        tl.fromTo(".bi-loader_wrapper-content", { opacity: 1 }, { opacity: 0 });
        tl.fromTo(
          ".bi-loader_wrapper",
          { height: "100vh" },
          {
            height: 0,
            duration: 0.8,
            ease: "power2.out",
            onStart: () => {
              window.dispatchEvent(new Event("resize"));
              eventBus.dispatch("siteLoaderEnd");
            },
          }
        );
      })
    }
  }, [visibility]);

  if (visibility) {
    return (
      <div className="bi-loader_wrapper">
        <div className="bi-loader_wrapper-content">
          <svg
            width="386"
            height="174"
            viewBox="0 0 386 174"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M384.211 3L245.13 76.7226L126 89.125L2 171.125"
              stroke="#1C3B60"
              strokeWidth="5"
            />
            <path
              d="M384.211 3L245.13 76.7226L126 89.125L2 171.125"
              stroke="#91C3FF"
              strokeWidth="5"
            />
          </svg>

          <svg
            width="208"
            height="26"
            viewBox="0 0 208 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="bi-loader_cambium"
          >
            <path
              d="M22.2713 21.9501C19.736 24.4444 16.5048 25.5918 12.9752 25.6417C3.92767 25.6417 0.0501504 19.356 0.000438553 12.9705C-0.0492733 6.53515 4.12652 0 12.9255 0C16.2562 0 19.388 1.24717 21.9233 3.7415L18.8412 6.73469C17.2504 5.13832 15.0631 4.43991 12.9255 4.43991C7.0098 4.43991 4.4745 8.87982 4.52421 12.9705C4.57393 17.0612 6.91038 21.3016 12.9752 21.2517C15.1128 21.2517 17.499 20.3537 19.1395 18.7574L22.2713 21.9501Z"
              fill="#1C3B60"
            />
            <path
              d="M48.1712 20.3538H35.3952L33.3074 24.9933H28.2865L39.2231 0.39917H44.244L55.28 24.9434H50.2591L48.1712 20.3538ZM41.7584 5.58738L37.2346 16.0636H46.3318L41.7584 5.58738Z"
              fill="#1C3B60"
            />
            <path
              d="M85.3059 7.13386L77.352 17.8096H76.4572L68.6027 7.13386L68.6525 24.9933H64.0293L63.9795 0.449057H69.2987L76.9543 10.9751L84.6099 0.39917H89.8794L89.9291 18.8572L85.3059 24.9434V7.13386Z"
              fill="#1C3B60"
            />
            <path
              d="M121.148 7.38322C121.148 9.27891 120.253 11.1247 117.967 12.1224C120.85 12.9705 121.894 16.0635 121.894 17.61C121.894 23.0975 117.817 24.8435 112.747 24.8435C108.869 24.8435 105.042 24.8435 101.114 24.8435L101.065 0.299316C104.892 0.299316 108.77 0.299316 112.697 0.299316C117.519 0.299316 121.098 2.44444 121.148 7.38322ZM105.638 10.1769H112.697C115.58 10.1769 116.525 8.9297 116.525 7.53288C116.525 6.38548 115.63 4.53968 112.697 4.53968H105.638V10.1769ZM105.688 20.5533H112.747C114.636 20.5533 117.32 19.9048 117.32 17.61C117.32 15.415 114.636 14.2676 112.747 14.2676H105.688V20.5533Z"
              fill="#1C3B60"
            />
            <path
              d="M132.184 24.8435L132.134 0.299316H136.708L136.758 18.907L132.184 24.8435Z"
              fill="#1C3B60"
            />
            <path
              d="M169.518 0.199463L169.567 14.2675C169.567 21.5509 165.143 25.2425 158.979 25.2924C153.113 25.2924 148.092 21.7505 148.092 14.3174L148.042 0.24935H152.616L152.665 14.3174C152.665 18.7573 155.201 21.102 159.029 21.102C162.856 21.102 164.994 18.5079 164.994 14.3174L164.944 0.24935L169.518 0.199463Z"
              fill="#1C3B60"
            />
            <path
              d="M202.377 6.88435L194.423 17.5601H193.529L185.724 6.88435L185.773 24.7438H181.15L181.101 0.199545H186.42L194.075 10.7256L201.731 0.149658H207L207.05 18.8571L202.427 24.7438L202.377 6.88435Z"
              fill="#1C3B60"
            />
          </svg>
        </div>
      </div>
    );
  }
  return <></>;
};

export default ScreenLoader;
