import React from "react";
import "../../../assets/styles/components/utils/Modals/aboutCambiumParallaxModal.scss";
import { useSelector, useDispatch} from "react-redux";
import { setModalAboutCambiumParallax } from "../../../assets/scripts/store/redux-slices/modals.js";
import gsap from "gsap";

function CambiumParallaxModal({ lang }) {
  const dispatch = useDispatch();

  const [lastScrollPosition, setLastScrollPosition] = React.useState(0);
  
  const modalIsOpen = useSelector(
    (state) => state.modals.modalAboutCambiumParallax
  );
  const closeModal = () => {
    let tl = gsap.timeline({
      onComplete: () => {
        dispatch(setModalAboutCambiumParallax(false));
        window?.scrollTo(0, lastScrollPosition);
      },
    });
    tl.fromTo(
      ".bi-cambium-parallax-modal__content",
      { translateY: 0, opacity: 1 },
      { translateY: 16, opacity: 0 }
    );
    tl.fromTo(".bi-cambium-parallax-modal", { opacity: 1 }, { opacity: 0 });
    tl.fromTo(
      ".bi-cambium-parallax-modal-container",
      { opacity: 1 },
      { opacity: 0 },
      "-=0.25"
    );
  };
  const openModal = () => {
    let tl = gsap.timeline();

    tl.fromTo(
      ".bi-cambium-parallax-modal-container",
      { opacity: 0 },
      { opacity: 1 }
    );
    tl.fromTo(
      ".bi-cambium-parallax-modal",
      { opacity: 0 },
      { opacity: 1 },
      "-=0.25"
    );
    tl.fromTo(
      ".bi-cambium-parallax-modal__content",
      { translateY: 16, opacity: 0 },
      { translateY: 0, opacity: 1 }
    );
  };
  React.useEffect(() => {
    if (modalIsOpen) {
      setLastScrollPosition(window.scrollY);
      openModal();
    }
  }, [modalIsOpen]);

  if (modalIsOpen) {
    return (
      <div className="bi-cambium-parallax-modal-container">
        <div className="bi-cambium-parallax-modal">
          <div
            className="bi-cambium-parallax-modal__content"
            onWheel={(event) => {
              event.stopPropagation();
            }}
          >
            <button>
              <p
                onClick={() => {
                  closeModal();
                }}
                className="bi-secondary-paragraph"
              >
                {lang === "fr" ? "Retour" : "Back"}
              </p>
            </button>
            <p className="bi-primary-paragraph">Définition de CAMBIUM</p>
            <p className="bi-secondary-paragraph">
              {lang === "fr"
                ? "“Le cambium est la fine couche de l’écorce des arbres, qui est responsable de la formation du bois et de leur croissance. En modifiant en permanence les propriétés du bois qu'il forme, il permet à l'arbre durant sa vie de s’adapter à la plupart des contraintes et variations de son environnement immédiat“. Comme lui, notre approche participe à la croissance de votre patrimoine de façon durable et pérenne, indépendamment des raisonnements court-termistes des marchés."
                : "“The cambium is the thin layer of tree bark, which is responsible for wood formation and growth. By permanently modifying the properties of the wood it forms, it allows the tree during its life to adapt to most of the constraints and variations of its immediate environment.“ Like it, our approach contributes to the growth of your wealth in a robust and sustainable way, independently of the short-termist reasoning of the markets."}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}

export default CambiumParallaxModal;
