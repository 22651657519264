import React from "react";
import "../../assets/styles/components/utils/logo.scss";

  
function Logo({ color, menuIsOpen }) {
  return (
    <>
      <div className="bi-logo-desktop">
        {color === "faqColorPage" ? (
          <svg
            width="208"
            height="26"
            viewBox="0 0 208 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.2708 21.9501C19.7355 24.4444 16.5043 25.5918 12.9747 25.6417C3.92718 25.6417 0.0496621 19.356 -4.97285e-05 12.9705C-0.0497615 6.53515 4.12603 0 12.925 0C16.2557 0 19.3876 1.24717 21.9229 3.7415L18.8407 6.73469C17.2499 5.13832 15.0626 4.43991 12.925 4.43991C7.00932 4.43991 4.47401 8.87982 4.52373 12.9705C4.57344 17.0612 6.90989 21.3016 12.9747 21.2517C15.1123 21.2517 17.4985 20.3537 19.139 18.7574L22.2708 21.9501Z"
              fill="white"
            />
            <path
              d="M48.1709 20.3537H35.3949L33.307 24.9932H28.2861L39.2227 0.399078H44.2436L55.2796 24.9433H50.2588L48.1709 20.3537ZM41.758 5.58729L37.2343 16.0635H46.3315L41.758 5.58729Z"
              fill="white"
            />
            <path
              d="M85.3054 7.13377L77.3515 17.8095H76.4567L68.6022 7.13377L68.6519 24.9932H64.0287L63.979 0.448965H69.2982L76.9538 10.975L84.6094 0.399078H89.8789L89.9286 18.8571L85.3054 24.9433V7.13377Z"
              fill="white"
            />
            <path
              d="M121.148 7.38322C121.148 9.27891 120.253 11.1247 117.966 12.1224C120.849 12.9705 121.893 16.0635 121.893 17.61C121.893 23.0975 117.817 24.8435 112.746 24.8435C108.869 24.8435 105.041 24.8435 101.114 24.8435L101.064 0.299316C104.892 0.299316 108.769 0.299316 112.697 0.299316C117.519 0.299316 121.098 2.44444 121.148 7.38322ZM105.637 10.1769H112.697C115.58 10.1769 116.524 8.9297 116.524 7.53288C116.524 6.38548 115.63 4.53968 112.697 4.53968H105.637V10.1769ZM105.687 20.5533H112.746C114.635 20.5533 117.32 19.9048 117.32 17.61C117.32 15.415 114.635 14.2676 112.746 14.2676H105.687V20.5533Z"
              fill="white"
            />
            <path
              d="M132.184 24.8435L132.134 0.299316H136.707L136.757 18.907L132.184 24.8435Z"
              fill="white"
            />
            <path
              d="M169.517 0.199554L169.567 14.2676C169.567 21.551 165.142 25.2426 158.978 25.2925C153.112 25.2925 148.091 21.7506 148.091 14.3175L148.042 0.249441H152.615L152.665 14.3175C152.665 18.7574 155.2 21.102 159.028 21.102C162.856 21.102 164.993 18.5079 164.993 14.3175L164.944 0.249441L169.517 0.199554Z"
              fill="white"
            />
            <path
              d="M202.377 6.88435L194.423 17.5601H193.528L185.723 6.88435L185.773 24.7438H181.15L181.1 0.199545H186.419L194.075 10.7256L201.731 0.149658H207L207.05 18.8571L202.426 24.7438L202.377 6.88435Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="208"
            height="26"
            viewBox="0 0 208 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.2713 21.9501C19.736 24.4444 16.5048 25.5918 12.9752 25.6417C3.92767 25.6417 0.0501504 19.356 0.000438553 12.9705C-0.0492733 6.53515 4.12652 0 12.9255 0C16.2562 0 19.388 1.24717 21.9233 3.7415L18.8412 6.73469C17.2504 5.13832 15.0631 4.43991 12.9255 4.43991C7.0098 4.43991 4.4745 8.87982 4.52421 12.9705C4.57393 17.0612 6.91038 21.3016 12.9752 21.2517C15.1128 21.2517 17.499 20.3537 19.1395 18.7574L22.2713 21.9501Z"
              fill="#1C3B60"
            />
            <path
              d="M48.1709 20.3537H35.3949L33.307 24.9932H28.2861L39.2227 0.399078H44.2436L55.2796 24.9433H50.2588L48.1709 20.3537ZM41.758 5.58729L37.2343 16.0635H46.3315L41.758 5.58729Z"
              fill="#1C3B60"
            />
            <path
              d="M85.3059 7.13377L77.352 17.8095H76.4572L68.6027 7.13377L68.6524 24.9932H64.0292L63.9795 0.448965H69.2987L76.9543 10.975L84.6099 0.399078H89.8794L89.9291 18.8571L85.3059 24.9433V7.13377Z"
              fill="#1C3B60"
            />
            <path
              d="M121.148 7.38322C121.148 9.27891 120.253 11.1247 117.966 12.1224C120.85 12.9705 121.894 16.0635 121.894 17.61C121.894 23.0975 117.817 24.8435 112.747 24.8435C108.869 24.8435 105.041 24.8435 101.114 24.8435L101.064 0.299316C104.892 0.299316 108.77 0.299316 112.697 0.299316C117.519 0.299316 121.098 2.44444 121.148 7.38322ZM105.638 10.1769H112.697C115.58 10.1769 116.525 8.9297 116.525 7.53288C116.525 6.38548 115.63 4.53968 112.697 4.53968H105.638V10.1769ZM105.688 20.5533H112.747C114.636 20.5533 117.32 19.9048 117.32 17.61C117.32 15.415 114.636 14.2676 112.747 14.2676H105.688V20.5533Z"
              fill="#1C3B60"
            />
            <path
              d="M132.184 24.8435L132.135 0.299316H136.708L136.758 18.907L132.184 24.8435Z"
              fill="#1C3B60"
            />
            <path
              d="M169.518 0.199554L169.567 14.2676C169.567 21.551 165.143 25.2426 158.979 25.2925C153.113 25.2925 148.092 21.7506 148.092 14.3175L148.042 0.249441H152.615L152.665 14.3175C152.665 18.7574 155.2 21.102 159.028 21.102C162.856 21.102 164.994 18.5079 164.994 14.3175L164.944 0.249441L169.518 0.199554Z"
              fill="#1C3B60"
            />
            <path
              d="M202.377 6.88435L194.423 17.5601H193.529L185.724 6.88435L185.774 24.7438H181.15L181.101 0.199545H186.42L194.075 10.7256L201.731 0.149658H207L207.05 18.8571L202.427 24.7438L202.377 6.88435Z"
              fill="#1C3B60"
            />
          </svg>
        )}
      </div>
      <div className="bi-logo-mobile">
        {color === "faqColorPage" || menuIsOpen === true ? (
          <svg
            width="93"
            height="19"
            viewBox="0 0 93 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >

            <path
              d="M10.006 9.47846C8.86691 10.5556 7.41518 11.051 5.82945 11.0726C1.76461 11.0726 0.0225313 8.35828 0.000197031 5.60091C-0.0221373 2.822 1.85394 0 5.80711 0C7.30351 0 8.71057 0.538549 9.84962 1.61565L8.46489 2.90816C7.7502 2.21882 6.76749 1.91723 5.80711 1.91723C3.14933 1.91723 2.01028 3.83447 2.03262 5.60091C2.05495 7.36735 3.10466 9.19841 5.82945 9.17687C6.78982 9.17687 7.86187 8.78911 8.5989 8.09977L10.006 9.47846Z"
              fill="white"
            />
            <path
              d="M21.6417 8.78911H15.9018L14.9638 10.7925H12.708L17.6216 0.172333H19.8773L24.8355 10.771H22.5798L21.6417 8.78911ZM18.7606 2.4127L16.7282 6.93651H20.8154L18.7606 2.4127Z"
              fill="white"
            />
            <path
              d="M38.3256 3.0805L34.7521 7.69047H34.35L30.8212 3.0805L30.8436 10.7925H28.7665L28.7441 0.193875H31.1339L34.5734 4.73923L38.0129 0.172333H40.3803L40.4026 8.14285L38.3256 10.771V3.0805Z"
              fill="white"
            />
            <path
              d="M54.4283 3.1882C54.4283 4.00679 54.0263 4.80384 52.9989 5.23468C54.2943 5.6009 54.7633 6.9365 54.7633 7.6043C54.7633 9.97391 52.9319 10.7279 50.6538 10.7279C48.9118 10.7279 47.192 10.7279 45.4276 10.7279L45.4053 0.129242C47.125 0.129242 48.8671 0.129242 50.6315 0.129242C52.7979 0.129242 54.406 1.05555 54.4283 3.1882ZM47.46 4.39455H50.6315C51.9269 4.39455 52.3512 3.856 52.3512 3.25282C52.3512 2.75736 51.9492 1.96031 50.6315 1.96031H47.46V4.39455ZM47.4824 8.87527H50.6538C51.5025 8.87527 52.7086 8.59523 52.7086 7.6043C52.7086 6.65645 51.5025 6.16099 50.6538 6.16099H47.4824V8.87527Z"
              fill="white"
            />
            <path
              d="M59.3866 10.7279L59.3643 0.129242H61.419L61.4414 8.16439L59.3866 10.7279Z"
              fill="white"
            />
            <path
              d="M76.1601 0.0861816L76.1825 6.16101C76.1825 9.30614 74.1947 10.9002 71.4253 10.9218C68.7898 10.9218 66.5341 9.3923 66.5341 6.18255L66.5117 0.107724H68.5665L68.5888 6.18255C68.5888 8.09979 69.7279 9.11226 71.4476 9.11226C73.1673 9.11226 74.1277 7.99208 74.1277 6.18255L74.1054 0.107724L76.1601 0.0861816Z"
              fill="white"
            />
            <path
              d="M90.9233 2.9728L87.3499 7.58278H86.9478L83.4414 2.9728L83.4637 10.6848H81.3866L81.3643 0.0861782H83.754L87.1935 4.63153L90.633 0.0646362H93.0004L93.0228 8.14287L90.9457 10.6848L90.9233 2.9728Z"
              fill="white"
            />
            <path
              d="M1.8985 17.6429C1.98784 17.6429 2.01018 17.6859 2.01018 17.729C2.01018 17.7936 1.98784 17.8367 1.8985 17.8367H-0.0445785C-0.133916 17.8367 -0.15625 17.7936 -0.15625 17.729C-0.15625 17.6644 -0.133916 17.6429 -0.0445785 17.6429H1.8985Z"
              fill="white"
            />
            <path
              d="M6.18701 16.8458C6.14234 16.8889 6.07534 16.8889 6.00833 16.8458C5.89666 16.7381 5.71799 16.6304 5.42764 16.6304C4.95862 16.6304 4.60127 16.9966 4.60127 17.4921C4.60127 17.966 4.95862 18.3322 5.44998 18.3322C5.71799 18.3322 5.89666 18.246 6.03067 18.1168C6.09767 18.0522 6.16467 18.0737 6.20934 18.1168C6.25401 18.1599 6.25401 18.2245 6.18701 18.2676C5.986 18.4399 5.74032 18.5476 5.44998 18.5476C4.82462 18.5476 4.37793 18.0737 4.37793 17.4921C4.37793 16.9104 4.82462 16.4365 5.42764 16.4365C5.74032 16.4365 5.986 16.5442 6.16467 16.7166C6.25401 16.7597 6.23168 16.8027 6.18701 16.8458Z"
              fill="white"
            />
            <path
              d="M7.90598 17.0181C8.33033 17.0181 8.66534 17.3628 8.66534 17.7721C8.66534 18.1814 8.35266 18.5261 7.90598 18.5261C7.48163 18.5261 7.16895 18.1814 7.16895 17.7721C7.16895 17.3628 7.48163 17.0181 7.90598 17.0181ZM7.90598 18.3106C8.21866 18.3106 8.41967 18.0737 8.41967 17.7721C8.41967 17.4705 8.19632 17.2335 7.90598 17.2335C7.5933 17.2335 7.39229 17.4705 7.39229 17.7721C7.39229 18.0737 7.5933 18.3106 7.90598 18.3106Z"
              fill="white"
            />
            <path
              d="M9.6709 17.1689C9.6709 17.0828 9.71557 17.0397 9.78257 17.0397C9.84957 17.0397 9.89424 17.0828 9.89424 17.1689V17.2335C9.98358 17.1043 10.1623 17.0181 10.3633 17.0181C10.7429 17.0181 10.944 17.2551 10.9663 17.6428V18.3753C10.9663 18.4614 10.9216 18.5045 10.8546 18.5045C10.7876 18.5045 10.7429 18.4614 10.7429 18.3753V17.6428C10.7429 17.3843 10.6089 17.2335 10.3633 17.2335C10.0729 17.2335 9.91658 17.449 9.91658 17.7936V18.3968C9.91658 18.483 9.87191 18.5261 9.8049 18.5261C9.7379 18.5261 9.69323 18.483 9.69323 18.3968L9.6709 17.1689Z"
              fill="white"
            />
            <path
              d="M12.3283 16.932V17.0397H12.5963C12.6856 17.0397 12.708 17.0828 12.708 17.1474C12.708 17.212 12.6856 17.2551 12.5963 17.2551H12.3283V18.3753C12.3283 18.4615 12.2836 18.5045 12.2166 18.5045C12.1496 18.5045 12.1049 18.4615 12.1049 18.3753V17.2551H11.9486C11.8592 17.2551 11.8369 17.212 11.8369 17.1474C11.8369 17.0828 11.8592 17.0397 11.9486 17.0397H12.1049V16.932C12.1049 16.5658 12.2836 16.415 12.6186 16.415C12.708 16.415 12.7303 16.4581 12.7303 16.5227C12.7303 16.5873 12.708 16.6304 12.6186 16.6304C12.3953 16.6304 12.3283 16.7381 12.3283 16.932Z"
              fill="white"
            />
            <path
              d="M13.8924 18.2891V17.2551H13.669C13.5797 17.2551 13.5574 17.212 13.5574 17.1474C13.5574 17.0828 13.5797 17.0397 13.669 17.0397H14.004C14.0711 17.0397 14.1157 17.0828 14.1157 17.1689V18.2891H14.3837C14.4731 18.2891 14.4954 18.3322 14.4954 18.3968C14.4954 18.4615 14.4731 18.5045 14.3837 18.5045H13.6244C13.535 18.5045 13.5127 18.4615 13.5127 18.3968C13.5127 18.3322 13.535 18.2891 13.6244 18.2891H13.8924ZM14.004 16.3934C14.1157 16.3934 14.2051 16.4796 14.2051 16.5873C14.2051 16.695 14.1157 16.7812 14.004 16.7812C13.8924 16.7812 13.803 16.695 13.803 16.5873C13.7807 16.4796 13.8924 16.3934 14.004 16.3934Z"
              fill="white"
            />
            <path
              d="M16.0363 16.9966C16.2596 16.9966 16.4383 17.0828 16.55 17.2336V17.1474C16.55 17.0612 16.5946 17.0182 16.6616 17.0182C16.7286 17.0182 16.7733 17.0612 16.7733 17.1474V18.3753C16.7733 18.4615 16.7286 18.5045 16.6616 18.5045C16.5946 18.5045 16.55 18.4615 16.55 18.3753V18.2891C16.4383 18.4399 16.2596 18.5261 16.0363 18.5261C15.6342 18.5261 15.3439 18.1814 15.3439 17.7721C15.3216 17.3413 15.6342 17.0182 16.0363 16.9966ZM16.0363 18.3107C16.3489 18.3107 16.5276 18.0737 16.5276 17.7721C16.5276 17.4705 16.3266 17.2336 16.0139 17.2336C15.7012 17.2336 15.5226 17.4705 15.5226 17.7721C15.5672 18.0737 15.7459 18.3107 16.0363 18.3107Z"
              fill="white"
            />
            <path
              d="M17.8447 17.1474C17.8447 17.0612 17.8894 17.0182 17.9564 17.0182C18.0234 17.0182 18.0681 17.0612 18.0681 17.1474V17.212C18.1574 17.0828 18.3361 16.9966 18.5371 16.9966C18.9168 16.9966 19.1178 17.2336 19.1401 17.6213V18.3538C19.1401 18.4399 19.0954 18.483 19.0284 18.483C18.9614 18.483 18.9168 18.4399 18.9168 18.3538V17.6213C18.9168 17.3628 18.7828 17.212 18.5371 17.212C18.2467 17.212 18.0904 17.4275 18.0904 17.7721V18.3753C18.0904 18.4615 18.0457 18.5045 17.9787 18.5045C17.9117 18.5045 17.8671 18.4615 17.8671 18.3753L17.8447 17.1474Z"
              fill="white"
            />
            <path
              d="M20.8824 17.212C20.5697 17.212 20.3687 17.449 20.3687 17.7506C20.3687 18.0522 20.5697 18.2891 20.9047 18.2891C21.0611 18.2891 21.1728 18.246 21.2621 18.1383C21.3291 18.0737 21.3738 18.0952 21.4184 18.1383C21.4631 18.1814 21.4631 18.2245 21.3961 18.2891C21.2844 18.3968 21.1058 18.483 20.8601 18.483C20.4357 18.483 20.123 18.1383 20.123 17.729C20.123 17.3197 20.4581 16.9751 20.8824 16.9751C21.0834 16.9751 21.2398 17.0397 21.3738 17.1474C21.4408 17.212 21.4184 17.2551 21.3961 17.2982C21.3514 17.3413 21.3068 17.3413 21.2398 17.2982C21.1281 17.2551 21.0388 17.212 20.8824 17.212Z"
              fill="white"
            />
            <path
              d="M23.5184 17.8152H22.558C22.5804 18.1168 22.7591 18.2891 23.0494 18.2891C23.2057 18.2891 23.3174 18.246 23.4068 18.1383C23.4738 18.0737 23.5184 18.0953 23.5631 18.1383C23.6078 18.1814 23.6078 18.2245 23.5408 18.2891C23.4291 18.3968 23.2504 18.483 23.0271 18.483C22.5804 18.483 22.29 18.1814 22.29 17.7506C22.29 17.3413 22.5804 16.9966 22.9824 16.9966C23.4514 16.9966 23.6301 17.3628 23.6301 17.686C23.6748 17.7721 23.6078 17.8152 23.5184 17.8152ZM22.558 17.6213H23.4291C23.4291 17.3628 23.2727 17.1905 23.0271 17.212C22.7591 17.1905 22.6027 17.3844 22.558 17.6213Z"
              fill="white"
            />
            <path
              d="M28.1417 17.5782C28.231 17.5782 28.2533 17.6213 28.2533 17.6644C28.2533 17.7075 28.231 17.7721 28.1417 17.7721H26.1986C26.1092 17.7721 26.0869 17.729 26.0869 17.6644C26.0869 17.5998 26.1092 17.5782 26.1986 17.5782H28.1417Z"
              fill="white"
            />
            <path
              d="M32.1165 16.3934C32.2059 16.3934 32.2282 16.4365 32.2282 16.5011C32.2282 16.5658 32.2059 16.6089 32.1165 16.6089H31.5358V18.3322C31.5358 18.4184 31.4912 18.4615 31.4241 18.4615C31.3571 18.4615 31.3125 18.4184 31.3125 18.3322V16.6089H30.7318C30.6425 16.6089 30.6201 16.5658 30.6201 16.5011C30.6201 16.4365 30.6425 16.3934 30.7318 16.3934H32.1165Z"
              fill="white"
            />
            <path
              d="M34.0154 17.6213C33.9484 17.6213 33.9037 17.5782 33.9037 17.4921C33.8814 17.2767 33.7697 17.1689 33.5464 17.1689C33.3007 17.1689 33.1667 17.3628 33.1667 17.6429V18.3107C33.1667 18.3968 33.122 18.4399 33.055 18.4399C32.988 18.4399 32.9434 18.3968 32.9434 18.3107V17.0828C32.9434 16.9966 32.988 16.9535 33.055 16.9535C33.122 16.9535 33.1667 16.9966 33.1667 17.0828V17.1689C33.256 17.0397 33.39 16.9535 33.5911 16.9535C33.8591 16.9535 34.1271 17.1259 34.1271 17.4921C34.1271 17.5998 34.0824 17.6213 34.0154 17.6213Z"
              fill="white"
            />
            <path
              d="M35.6904 16.9751C35.9138 16.9751 36.0924 17.0612 36.2041 17.212V17.1259C36.2041 17.0397 36.2488 16.9966 36.3158 16.9966C36.3828 16.9966 36.4274 17.0397 36.4274 17.1259V18.3538C36.4274 18.4399 36.3828 18.483 36.3158 18.483C36.2488 18.483 36.2041 18.4399 36.2041 18.3538V18.2676C36.0924 18.4184 35.9138 18.5045 35.6904 18.5045C35.2884 18.5045 34.998 18.1599 34.998 17.7506C34.998 17.3413 35.2884 16.9751 35.6904 16.9751ZM35.7127 18.2676C36.0254 18.2676 36.2041 18.0306 36.2041 17.729C36.2041 17.4274 36.0031 17.1905 35.6904 17.1905C35.3777 17.1905 35.1991 17.4274 35.1991 17.729C35.1991 18.0306 35.4001 18.2676 35.7127 18.2676Z"
              fill="white"
            />
            <path
              d="M37.5215 17.1043C37.5215 17.0181 37.5662 16.9751 37.6332 16.9751C37.7002 16.9751 37.7448 17.0181 37.7448 17.1043V17.1689C37.8342 17.0397 38.0128 16.9535 38.2138 16.9535C38.5935 16.9535 38.7945 17.1905 38.8169 17.5782V18.3107C38.8169 18.3968 38.7722 18.4399 38.7052 18.4399C38.6382 18.4399 38.5935 18.3968 38.5935 18.3107V17.5782C38.5935 17.3197 38.4595 17.1689 38.2138 17.1689C37.9235 17.1689 37.7672 17.3844 37.7672 17.729V18.3322C37.7672 18.4184 37.7225 18.4615 37.6555 18.4615C37.5885 18.4615 37.5438 18.4184 37.5438 18.3322L37.5215 17.1043Z"
              fill="white"
            />
            <path
              d="M40.3139 17.1689C40.1576 17.1689 40.0459 17.2551 40.0459 17.3628C40.0459 17.4705 40.1129 17.5352 40.2692 17.5782L40.4926 17.6213C40.7606 17.686 40.9169 17.8152 40.9169 18.0306C40.9169 18.2891 40.6936 18.4615 40.3586 18.4615C40.1129 18.4615 39.9342 18.3753 39.8226 18.2676C39.7556 18.203 39.7779 18.1599 39.8226 18.1168C39.8672 18.0737 39.9119 18.0737 39.9789 18.1168C40.0906 18.203 40.2246 18.2676 40.3586 18.2676C40.5596 18.2676 40.6936 18.1814 40.6936 18.0522C40.6936 17.966 40.6266 17.8798 40.4703 17.8367L40.2469 17.7937C39.9789 17.729 39.8226 17.5998 39.8226 17.3844C39.8226 17.1259 40.0682 16.9751 40.3363 16.9751C40.5149 16.9751 40.6713 17.0182 40.7829 17.1474C40.8499 17.212 40.8499 17.2551 40.8053 17.2982C40.7606 17.3413 40.6936 17.3413 40.6266 17.2767C40.5596 17.2336 40.4703 17.1689 40.3139 17.1689Z"
              fill="white"
            />
            <path
              d="M41.9219 17.1043C41.9219 17.0181 41.9665 16.9751 42.0335 16.9751C42.1005 16.9751 42.1452 17.0181 42.1452 17.1043V17.1905C42.2792 17.0397 42.4356 16.9535 42.6589 16.9535C43.0609 16.9535 43.3513 17.2982 43.3513 17.7075C43.3513 18.1168 43.0833 18.4615 42.6589 18.4615C42.4579 18.4615 42.2792 18.3753 42.1676 18.246V18.8923C42.1676 18.9785 42.1229 19.0216 42.0559 19.0216C41.9889 19.0216 41.9442 18.9785 41.9442 18.8923L41.9219 17.1043ZM42.6366 17.1689C42.3462 17.1689 42.1452 17.4059 42.1452 17.7075C42.1452 18.0091 42.3239 18.246 42.6366 18.246C42.9493 18.246 43.1279 18.0091 43.1279 17.7075C43.1056 17.4059 42.9269 17.1474 42.6366 17.1689Z"
              fill="white"
            />
            <path
              d="M44.9365 16.9535C45.1598 16.9535 45.3385 17.0397 45.4502 17.1905V17.1043C45.4502 17.0181 45.4949 16.9751 45.5619 16.9751C45.6289 16.9751 45.6735 17.0181 45.6735 17.1043V18.3322C45.6735 18.4184 45.6289 18.4615 45.5619 18.4615C45.4949 18.4615 45.4502 18.4184 45.4502 18.3322V18.246C45.3385 18.3968 45.1598 18.483 44.9365 18.483C44.5345 18.483 44.2441 18.1383 44.2441 17.729C44.2441 17.3197 44.5568 16.9535 44.9365 16.9535ZM44.9588 18.246C45.2715 18.246 45.4502 18.0091 45.4502 17.7075C45.4502 17.4059 45.2492 17.1689 44.9365 17.1689C44.6238 17.1689 44.4451 17.4059 44.4451 17.7075C44.4451 18.0091 44.6685 18.246 44.9588 18.246Z"
              fill="white"
            />
            <path
              d="M47.8406 17.5998C47.7736 17.5998 47.7289 17.5567 47.7289 17.4705C47.7066 17.2551 47.5949 17.1474 47.3716 17.1474C47.1259 17.1474 46.9919 17.3413 46.9919 17.6213V18.2891C46.9919 18.3753 46.9472 18.4184 46.8802 18.4184C46.8132 18.4184 46.7686 18.3753 46.7686 18.2891V17.0612C46.7686 16.9751 46.8132 16.932 46.8802 16.932C46.9472 16.932 46.9919 16.9751 46.9919 17.0612V17.1474C47.0812 17.0181 47.2152 16.932 47.4163 16.932C47.6843 16.932 47.9523 17.1043 47.9523 17.4705C47.9523 17.5567 47.9076 17.5998 47.8406 17.5998Z"
              fill="white"
            />
            <path
              d="M50.0067 17.7506H49.0463C49.0687 18.0522 49.2473 18.2245 49.5377 18.2245C49.694 18.2245 49.8057 18.1814 49.895 18.0737C49.962 18.0091 50.0067 18.0306 50.0514 18.0737C50.096 18.1168 50.096 18.1599 50.029 18.2245C49.9174 18.3322 49.7387 18.4184 49.5154 18.4184C49.0687 18.4184 48.7783 18.1168 48.7783 17.6859C48.7783 17.2766 49.0687 16.932 49.4707 16.932C49.9397 16.932 50.1184 17.2982 50.1184 17.6213C50.163 17.7075 50.096 17.7506 50.0067 17.7506ZM49.0687 17.5782H49.9397C49.9397 17.3197 49.7834 17.1474 49.5377 17.1689C49.2697 17.1474 49.091 17.3413 49.0687 17.5782Z"
              fill="white"
            />
            <path
              d="M51.168 17.0828C51.168 16.9966 51.2126 16.9535 51.2796 16.9535C51.3466 16.9535 51.3913 16.9966 51.3913 17.0828V17.1474C51.4806 17.0181 51.6593 16.932 51.8603 16.932C52.24 16.932 52.441 17.1689 52.4634 17.5567V18.2891C52.4634 18.3753 52.4187 18.4184 52.3517 18.4184C52.2847 18.4184 52.24 18.3753 52.24 18.2891V17.5567C52.24 17.2982 52.106 17.1474 51.8603 17.1474C51.57 17.1474 51.4136 17.3628 51.4136 17.7075V18.3107C51.4136 18.3968 51.369 18.4399 51.302 18.4399C51.235 18.4399 51.1903 18.3968 51.1903 18.3107L51.168 17.0828Z"
              fill="white"
            />
            <path
              d="M54.2057 17.1474C53.893 17.1474 53.692 17.3844 53.692 17.6859C53.692 17.9875 53.893 18.2245 54.228 18.2245C54.3843 18.2245 54.496 18.1814 54.5853 18.0737C54.6523 18.0091 54.697 18.0306 54.7417 18.0737C54.7863 18.1168 54.7863 18.1599 54.7193 18.2245C54.6077 18.3322 54.429 18.4184 54.1833 18.4184C53.759 18.4184 53.4463 18.0737 53.4463 17.6644C53.4463 17.2551 53.7813 16.9104 54.2057 16.9104C54.4067 16.9104 54.563 16.9751 54.697 17.0828C54.764 17.1474 54.7417 17.1905 54.7193 17.2336C54.6747 17.2766 54.63 17.2766 54.563 17.2336C54.429 17.1905 54.3397 17.1474 54.2057 17.1474Z"
              fill="white"
            />
            <path
              d="M56.8182 17.7506H55.8579C55.8802 18.0522 56.0589 18.2245 56.3492 18.2245C56.5056 18.2245 56.6172 18.1814 56.7066 18.0737C56.7736 18.0091 56.8182 18.0306 56.8629 18.0737C56.9076 18.1168 56.9076 18.1599 56.8406 18.2245C56.7289 18.3322 56.5502 18.4184 56.3269 18.4184C55.8802 18.4184 55.5898 18.1168 55.5898 17.6859C55.5898 17.2766 55.8802 16.932 56.2822 16.932C56.7512 16.932 56.9299 17.2982 56.9299 17.6213C56.9746 17.7075 56.9299 17.7506 56.8182 17.7506ZM55.8802 17.5567H56.7512C56.7512 17.2982 56.5949 17.1259 56.3492 17.1474C56.0812 17.1259 55.9249 17.3197 55.8802 17.5567Z"
              fill="white"
            />
            <path
              d="M61.4638 17.5136C61.5531 17.5136 61.5755 17.5567 61.5755 17.5998C61.5755 17.6644 61.5531 17.7075 61.4638 17.7075H59.4984C59.4091 17.7075 59.3867 17.6644 59.3867 17.5998C59.3867 17.5352 59.4091 17.5136 59.4984 17.5136H61.4638Z"
              fill="white"
            />
            <path
              d="M64.8142 16.3288C65.2832 16.3288 65.5959 16.5873 65.5959 16.9751C65.5959 17.3628 65.3055 17.6213 64.8142 17.6213H64.3005V18.2676C64.3005 18.3537 64.2558 18.3968 64.1888 18.3968C64.1218 18.3968 64.0771 18.3537 64.0771 18.2676V16.458C64.0771 16.3934 64.1442 16.3288 64.2112 16.3288H64.8142ZM64.3005 16.5442V17.4274H64.8365C65.1492 17.4274 65.3502 17.2766 65.3502 16.9966C65.3502 16.7166 65.1492 16.5658 64.8365 16.5658L64.3005 16.5442Z"
              fill="white"
            />
            <path
              d="M67.5614 17.729H66.601C66.6234 18.0306 66.802 18.2029 67.0924 18.2029C67.2487 18.2029 67.3604 18.1599 67.4497 18.0522C67.5167 17.9875 67.5614 18.0091 67.6061 18.0522C67.6507 18.0952 67.6507 18.1383 67.5837 18.2029C67.4721 18.3107 67.2934 18.3968 67.07 18.3968C66.6234 18.3968 66.333 18.0952 66.333 17.6644C66.333 17.2551 66.6234 16.9104 67.0254 16.9104C67.4944 16.9104 67.6731 17.2766 67.6731 17.5998C67.7177 17.6859 67.6731 17.729 67.5614 17.729ZM66.6234 17.5351H67.4944C67.4944 17.2766 67.338 17.1043 67.0924 17.1259C66.8244 17.1259 66.668 17.2982 66.6234 17.5351Z"
              fill="white"
            />
            <path
              d="M69.8172 17.5567C69.7502 17.5567 69.7055 17.5136 69.7055 17.4274C69.6832 17.212 69.5715 17.1043 69.3481 17.1043C69.1025 17.1043 68.9685 17.2982 68.9685 17.5782V18.246C68.9685 18.3322 68.9238 18.3753 68.8568 18.3753C68.7898 18.3753 68.7451 18.3322 68.7451 18.246V17.0181C68.7451 16.932 68.7898 16.8889 68.8568 16.8889C68.9238 16.8889 68.9685 16.932 68.9685 17.0181V17.1043C69.0578 16.9751 69.1918 16.8889 69.3928 16.8889C69.6608 16.8889 69.9288 17.0612 69.9288 17.4274C69.9288 17.5351 69.8618 17.5567 69.8172 17.5567Z"
              fill="white"
            />
            <path
              d="M71.1574 16.8243V16.932H71.4254C71.5147 16.932 71.5371 16.9751 71.5371 17.0397C71.5371 17.0828 71.5147 17.1474 71.4254 17.1474H71.1574V18.2676C71.1574 18.3537 71.1127 18.3968 71.0457 18.3968C70.9787 18.3968 70.934 18.3537 70.934 18.2676V17.1474H70.7777C70.6883 17.1474 70.666 17.1043 70.666 17.0397C70.666 16.9966 70.6883 16.932 70.7777 16.932H70.934V16.8243C70.934 16.458 71.1127 16.3073 71.4477 16.3073C71.5371 16.3073 71.5594 16.3503 71.5594 16.415C71.5594 16.458 71.5371 16.5227 71.4477 16.5227C71.2244 16.5227 71.1574 16.6304 71.1574 16.8243Z"
              fill="white"
            />
            <path
              d="M73.0779 16.8889C73.5022 16.8889 73.8372 17.2336 73.8372 17.6429C73.8372 18.0522 73.5245 18.3968 73.0779 18.3968C72.6535 18.3968 72.3408 18.0522 72.3408 17.6429C72.3408 17.2336 72.6535 16.9104 73.0779 16.8889ZM73.0779 18.2029C73.3905 18.2029 73.5915 17.966 73.5915 17.6644C73.5915 17.3628 73.3682 17.1258 73.0779 17.1258C72.7652 17.1258 72.5642 17.3628 72.5642 17.6644C72.5642 17.966 72.7652 18.2029 73.0779 18.2029Z"
              fill="white"
            />
            <path
              d="M75.9148 17.5567C75.8478 17.5567 75.8031 17.5136 75.8031 17.4274C75.7808 17.212 75.6691 17.1043 75.4458 17.1043C75.2001 17.1043 75.0661 17.2982 75.0661 17.5782V18.246C75.0661 18.3322 75.0214 18.3753 74.9544 18.3753C74.8874 18.3753 74.8428 18.3322 74.8428 18.246V17.0181C74.8428 16.932 74.8874 16.8889 74.9544 16.8889C75.0214 16.8889 75.0661 16.932 75.0661 17.0181V17.1043C75.1555 16.9751 75.2895 16.8889 75.4905 16.8889C75.7585 16.8889 76.0265 17.0612 76.0265 17.4274C76.0265 17.5136 75.9818 17.5567 75.9148 17.5567Z"
              fill="white"
            />
            <path
              d="M78.8624 17.5136C78.8624 17.2551 78.7507 17.1043 78.5274 17.1043C78.2817 17.1043 78.1477 17.2766 78.1477 17.5782V18.2676C78.1477 18.3537 78.103 18.3968 78.036 18.3968C77.969 18.3968 77.9244 18.3537 77.9244 18.2676V17.5351C77.9244 17.2551 77.8127 17.1043 77.5893 17.1043C77.3437 17.1043 77.2097 17.3197 77.2097 17.5782V18.2676C77.2097 18.3537 77.165 18.3968 77.098 18.3968C77.031 18.3968 76.9863 18.3537 76.9863 18.2676V17.0397C76.9863 16.9535 77.031 16.9104 77.098 16.9104C77.165 16.9104 77.2097 16.9535 77.2097 17.0397V17.0828C77.299 16.9751 77.433 16.8889 77.6117 16.8889C77.8127 16.8889 77.9914 16.9751 78.0584 17.1258C78.17 16.9751 78.3487 16.8889 78.5274 16.8889C78.8847 16.8889 79.0858 17.1258 79.0858 17.5136V18.246C79.0858 18.3322 79.0411 18.3753 78.9741 18.3753C78.9071 18.3753 78.8624 18.3322 78.8624 18.246V17.5136Z"
              fill="white"
            />
            <path
              d="M80.7832 16.8889C81.0065 16.8889 81.1852 16.9751 81.2969 17.1258V17.0397C81.2969 16.9535 81.3415 16.9104 81.4085 16.9104C81.4755 16.9104 81.5202 16.9535 81.5202 17.0397V18.2676C81.5202 18.3537 81.4755 18.3968 81.4085 18.3968C81.3415 18.3968 81.2969 18.3537 81.2969 18.2676V18.1814C81.1852 18.3322 81.0065 18.4184 80.7832 18.4184C80.3812 18.4184 80.0908 18.0737 80.0908 17.6644C80.0908 17.2551 80.3812 16.8889 80.7832 16.8889ZM80.8055 18.1814C81.1182 18.1814 81.2969 17.9444 81.2969 17.6429C81.2969 17.3413 81.0959 17.1043 80.7832 17.1043C80.4705 17.1043 80.2918 17.3413 80.2918 17.6429C80.3142 17.9444 80.4928 18.1814 80.8055 18.1814Z"
              fill="white"
            />
            <path
              d="M82.6143 17.0181C82.6143 16.932 82.6589 16.8889 82.7259 16.8889C82.7929 16.8889 82.8376 16.932 82.8376 17.0181V17.0828C82.9269 16.9535 83.1056 16.8673 83.3066 16.8673C83.6863 16.8673 83.8873 17.1043 83.9097 17.4921V18.2245C83.9097 18.3106 83.865 18.3537 83.798 18.3537C83.731 18.3537 83.6863 18.3106 83.6863 18.2245V17.4921C83.6863 17.2336 83.5523 17.0828 83.3066 17.0828C83.0163 17.0828 82.8599 17.2982 82.8599 17.6429V18.246C82.8599 18.3322 82.8153 18.3753 82.7483 18.3753C82.6813 18.3753 82.6366 18.3322 82.6366 18.246L82.6143 17.0181Z"
              fill="white"
            />
            <path
              d="M85.6295 17.0828C85.3168 17.0828 85.1158 17.3197 85.1158 17.6213C85.1158 17.9229 85.3168 18.1599 85.6518 18.1599C85.8082 18.1599 85.9198 18.1168 86.0092 18.0091C86.0762 17.9444 86.1208 17.966 86.1655 18.0091C86.2102 18.0521 86.2102 18.0952 86.1432 18.1599C86.0315 18.2676 85.8528 18.3537 85.6071 18.3537C85.1828 18.3537 84.8701 18.0091 84.8701 17.5998C84.8701 17.1905 85.2051 16.8458 85.6295 16.8458C85.8305 16.8458 85.9868 16.9104 86.1208 17.0181C86.1878 17.0828 86.1655 17.1258 86.1432 17.1689C86.0985 17.212 86.0538 17.212 85.9868 17.1689C85.8752 17.1258 85.7858 17.0828 85.6295 17.0828Z"
              fill="white"
            />
            <path
              d="M88.2655 17.6859H87.3051C87.3275 17.9875 87.5061 18.1599 87.7965 18.1599C87.9528 18.1599 88.0645 18.1168 88.1538 18.0091C88.2208 17.9444 88.2655 17.966 88.3102 18.0091C88.3548 18.0521 88.3548 18.0952 88.2878 18.1599C88.1762 18.2676 87.9975 18.3537 87.7741 18.3537C87.3275 18.3537 87.0371 18.0521 87.0371 17.6213C87.0371 17.212 87.3274 16.8673 87.7295 16.8673C88.1985 16.8673 88.3772 17.2336 88.3772 17.5567C88.4218 17.6428 88.3548 17.6859 88.2655 17.6859ZM87.3275 17.4921H88.1985C88.1985 17.2336 88.0422 17.0612 87.7965 17.0828C87.5285 17.0828 87.3498 17.2551 87.3275 17.4921Z"
              fill="white"
            />
            <path
              d="M92.8886 17.4705C92.9779 17.4705 93.0003 17.5136 93.0003 17.5567C93.0003 17.6213 92.9779 17.6644 92.8886 17.6644H90.9232C90.8339 17.6644 90.8115 17.6213 90.8115 17.5567C90.8115 17.4921 90.8339 17.4705 90.9232 17.4705H92.8886Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width="93"
            height="19"
            viewBox="0 0 93 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1025_1862)">
              <path
                d="M10.006 9.47846C8.86691 10.5556 7.41518 11.051 5.82945 11.0726C1.76461 11.0726 0.0225313 8.35828 0.000197031 5.60091C-0.0221373 2.822 1.85394 0 5.80711 0C7.30351 0 8.71057 0.538549 9.84962 1.61565L8.46489 2.90816C7.7502 2.21882 6.76749 1.91723 5.80711 1.91723C3.14933 1.91723 2.01028 3.83447 2.03262 5.60091C2.05495 7.36735 3.10466 9.19841 5.82945 9.17687C6.78982 9.17687 7.86187 8.78911 8.5989 8.09977L10.006 9.47846Z"
                fill="#002D62"
              />
              <path
                d="M21.6417 8.78911H15.9018L14.9638 10.7925H12.708L17.6216 0.172333H19.8773L24.8355 10.771H22.5798L21.6417 8.78911ZM18.7606 2.4127L16.7282 6.93651H20.8154L18.7606 2.4127Z"
                fill="#002D62"
              />
              <path
                d="M38.3256 3.0805L34.7521 7.69047H34.35L30.8212 3.0805L30.8436 10.7925H28.7665L28.7441 0.193875H31.1339L34.5734 4.73923L38.0129 0.172333H40.3803L40.4026 8.14285L38.3256 10.771V3.0805Z"
                fill="#002D62"
              />
              <path
                d="M54.4283 3.1882C54.4283 4.00679 54.0263 4.80384 52.9989 5.23468C54.2943 5.6009 54.7633 6.9365 54.7633 7.6043C54.7633 9.97391 52.9319 10.7279 50.6538 10.7279C48.9118 10.7279 47.192 10.7279 45.4276 10.7279L45.4053 0.129242C47.125 0.129242 48.8671 0.129242 50.6315 0.129242C52.7979 0.129242 54.406 1.05555 54.4283 3.1882ZM47.46 4.39455H50.6315C51.9269 4.39455 52.3512 3.856 52.3512 3.25282C52.3512 2.75736 51.9492 1.96031 50.6315 1.96031H47.46V4.39455ZM47.4824 8.87527H50.6538C51.5025 8.87527 52.7086 8.59523 52.7086 7.6043C52.7086 6.65645 51.5025 6.16099 50.6538 6.16099H47.4824V8.87527Z"
                fill="#002D62"
              />
              <path
                d="M59.3866 10.7279L59.3643 0.129242H61.419L61.4414 8.16439L59.3866 10.7279Z"
                fill="#002D62"
              />
              <path
                d="M76.1601 0.0861816L76.1825 6.16101C76.1825 9.30614 74.1947 10.9002 71.4253 10.9218C68.7898 10.9218 66.5341 9.3923 66.5341 6.18255L66.5117 0.107724H68.5665L68.5888 6.18255C68.5888 8.09979 69.7279 9.11226 71.4476 9.11226C73.1673 9.11226 74.1277 7.99208 74.1277 6.18255L74.1054 0.107724L76.1601 0.0861816Z"
                fill="#002D62"
              />
              <path
                d="M90.9233 2.9728L87.3499 7.58278H86.9478L83.4414 2.9728L83.4637 10.6848H81.3866L81.3643 0.0861782H83.754L87.1935 4.63153L90.633 0.0646362H93.0004L93.0228 8.14287L90.9457 10.6848L90.9233 2.9728Z"
                fill="#002D62"
              />
              <path
                d="M1.8985 17.6429C1.98784 17.6429 2.01018 17.6859 2.01018 17.729C2.01018 17.7936 1.98784 17.8367 1.8985 17.8367H-0.0445785C-0.133916 17.8367 -0.15625 17.7936 -0.15625 17.729C-0.15625 17.6644 -0.133916 17.6429 -0.0445785 17.6429H1.8985Z"
                fill="#868786"
              />
              <path
                d="M6.18701 16.8458C6.14234 16.8889 6.07534 16.8889 6.00833 16.8458C5.89666 16.7381 5.71799 16.6304 5.42764 16.6304C4.95862 16.6304 4.60127 16.9966 4.60127 17.4921C4.60127 17.966 4.95862 18.3322 5.44998 18.3322C5.71799 18.3322 5.89666 18.246 6.03067 18.1168C6.09767 18.0522 6.16467 18.0737 6.20934 18.1168C6.25401 18.1599 6.25401 18.2245 6.18701 18.2676C5.986 18.4399 5.74032 18.5476 5.44998 18.5476C4.82462 18.5476 4.37793 18.0737 4.37793 17.4921C4.37793 16.9104 4.82462 16.4365 5.42764 16.4365C5.74032 16.4365 5.986 16.5442 6.16467 16.7166C6.25401 16.7597 6.23168 16.8027 6.18701 16.8458Z"
                fill="#868786"
              />
              <path
                d="M7.90598 17.0181C8.33033 17.0181 8.66534 17.3628 8.66534 17.7721C8.66534 18.1814 8.35266 18.5261 7.90598 18.5261C7.48163 18.5261 7.16895 18.1814 7.16895 17.7721C7.16895 17.3628 7.48163 17.0181 7.90598 17.0181ZM7.90598 18.3106C8.21866 18.3106 8.41967 18.0737 8.41967 17.7721C8.41967 17.4705 8.19632 17.2335 7.90598 17.2335C7.5933 17.2335 7.39229 17.4705 7.39229 17.7721C7.39229 18.0737 7.5933 18.3106 7.90598 18.3106Z"
                fill="#868786"
              />
              <path
                d="M9.6709 17.1689C9.6709 17.0828 9.71557 17.0397 9.78257 17.0397C9.84957 17.0397 9.89424 17.0828 9.89424 17.1689V17.2335C9.98358 17.1043 10.1623 17.0181 10.3633 17.0181C10.7429 17.0181 10.944 17.2551 10.9663 17.6428V18.3753C10.9663 18.4614 10.9216 18.5045 10.8546 18.5045C10.7876 18.5045 10.7429 18.4614 10.7429 18.3753V17.6428C10.7429 17.3843 10.6089 17.2335 10.3633 17.2335C10.0729 17.2335 9.91658 17.449 9.91658 17.7936V18.3968C9.91658 18.483 9.87191 18.5261 9.8049 18.5261C9.7379 18.5261 9.69323 18.483 9.69323 18.3968L9.6709 17.1689Z"
                fill="#868786"
              />
              <path
                d="M12.3283 16.932V17.0397H12.5963C12.6856 17.0397 12.708 17.0828 12.708 17.1474C12.708 17.212 12.6856 17.2551 12.5963 17.2551H12.3283V18.3753C12.3283 18.4615 12.2836 18.5045 12.2166 18.5045C12.1496 18.5045 12.1049 18.4615 12.1049 18.3753V17.2551H11.9486C11.8592 17.2551 11.8369 17.212 11.8369 17.1474C11.8369 17.0828 11.8592 17.0397 11.9486 17.0397H12.1049V16.932C12.1049 16.5658 12.2836 16.415 12.6186 16.415C12.708 16.415 12.7303 16.4581 12.7303 16.5227C12.7303 16.5873 12.708 16.6304 12.6186 16.6304C12.3953 16.6304 12.3283 16.7381 12.3283 16.932Z"
                fill="#868786"
              />
              <path
                d="M13.8924 18.2891V17.2551H13.669C13.5797 17.2551 13.5574 17.212 13.5574 17.1474C13.5574 17.0828 13.5797 17.0397 13.669 17.0397H14.004C14.0711 17.0397 14.1157 17.0828 14.1157 17.1689V18.2891H14.3837C14.4731 18.2891 14.4954 18.3322 14.4954 18.3968C14.4954 18.4615 14.4731 18.5045 14.3837 18.5045H13.6244C13.535 18.5045 13.5127 18.4615 13.5127 18.3968C13.5127 18.3322 13.535 18.2891 13.6244 18.2891H13.8924ZM14.004 16.3934C14.1157 16.3934 14.2051 16.4796 14.2051 16.5873C14.2051 16.695 14.1157 16.7812 14.004 16.7812C13.8924 16.7812 13.803 16.695 13.803 16.5873C13.7807 16.4796 13.8924 16.3934 14.004 16.3934Z"
                fill="#868786"
              />
              <path
                d="M16.0363 16.9966C16.2596 16.9966 16.4383 17.0828 16.55 17.2336V17.1474C16.55 17.0612 16.5946 17.0182 16.6616 17.0182C16.7286 17.0182 16.7733 17.0612 16.7733 17.1474V18.3753C16.7733 18.4615 16.7286 18.5045 16.6616 18.5045C16.5946 18.5045 16.55 18.4615 16.55 18.3753V18.2891C16.4383 18.4399 16.2596 18.5261 16.0363 18.5261C15.6342 18.5261 15.3439 18.1814 15.3439 17.7721C15.3216 17.3413 15.6342 17.0182 16.0363 16.9966ZM16.0363 18.3107C16.3489 18.3107 16.5276 18.0737 16.5276 17.7721C16.5276 17.4705 16.3266 17.2336 16.0139 17.2336C15.7012 17.2336 15.5226 17.4705 15.5226 17.7721C15.5672 18.0737 15.7459 18.3107 16.0363 18.3107Z"
                fill="#868786"
              />
              <path
                d="M17.8447 17.1474C17.8447 17.0612 17.8894 17.0182 17.9564 17.0182C18.0234 17.0182 18.0681 17.0612 18.0681 17.1474V17.212C18.1574 17.0828 18.3361 16.9966 18.5371 16.9966C18.9168 16.9966 19.1178 17.2336 19.1401 17.6213V18.3538C19.1401 18.4399 19.0954 18.483 19.0284 18.483C18.9614 18.483 18.9168 18.4399 18.9168 18.3538V17.6213C18.9168 17.3628 18.7828 17.212 18.5371 17.212C18.2467 17.212 18.0904 17.4275 18.0904 17.7721V18.3753C18.0904 18.4615 18.0457 18.5045 17.9787 18.5045C17.9117 18.5045 17.8671 18.4615 17.8671 18.3753L17.8447 17.1474Z"
                fill="#868786"
              />
              <path
                d="M20.8824 17.212C20.5697 17.212 20.3687 17.449 20.3687 17.7506C20.3687 18.0522 20.5697 18.2891 20.9047 18.2891C21.0611 18.2891 21.1728 18.246 21.2621 18.1383C21.3291 18.0737 21.3738 18.0952 21.4184 18.1383C21.4631 18.1814 21.4631 18.2245 21.3961 18.2891C21.2844 18.3968 21.1058 18.483 20.8601 18.483C20.4357 18.483 20.123 18.1383 20.123 17.729C20.123 17.3197 20.4581 16.9751 20.8824 16.9751C21.0834 16.9751 21.2398 17.0397 21.3738 17.1474C21.4408 17.212 21.4184 17.2551 21.3961 17.2982C21.3514 17.3413 21.3068 17.3413 21.2398 17.2982C21.1281 17.2551 21.0388 17.212 20.8824 17.212Z"
                fill="#868786"
              />
              <path
                d="M23.5184 17.8152H22.558C22.5804 18.1168 22.7591 18.2891 23.0494 18.2891C23.2057 18.2891 23.3174 18.246 23.4068 18.1383C23.4738 18.0737 23.5184 18.0953 23.5631 18.1383C23.6078 18.1814 23.6078 18.2245 23.5408 18.2891C23.4291 18.3968 23.2504 18.483 23.0271 18.483C22.5804 18.483 22.29 18.1814 22.29 17.7506C22.29 17.3413 22.5804 16.9966 22.9824 16.9966C23.4514 16.9966 23.6301 17.3628 23.6301 17.686C23.6748 17.7721 23.6078 17.8152 23.5184 17.8152ZM22.558 17.6213H23.4291C23.4291 17.3628 23.2727 17.1905 23.0271 17.212C22.7591 17.1905 22.6027 17.3844 22.558 17.6213Z"
                fill="#868786"
              />
              <path
                d="M28.1417 17.5782C28.231 17.5782 28.2533 17.6213 28.2533 17.6644C28.2533 17.7075 28.231 17.7721 28.1417 17.7721H26.1986C26.1092 17.7721 26.0869 17.729 26.0869 17.6644C26.0869 17.5998 26.1092 17.5782 26.1986 17.5782H28.1417Z"
                fill="#868786"
              />
              <path
                d="M32.1165 16.3934C32.2059 16.3934 32.2282 16.4365 32.2282 16.5011C32.2282 16.5658 32.2059 16.6089 32.1165 16.6089H31.5358V18.3322C31.5358 18.4184 31.4912 18.4615 31.4241 18.4615C31.3571 18.4615 31.3125 18.4184 31.3125 18.3322V16.6089H30.7318C30.6425 16.6089 30.6201 16.5658 30.6201 16.5011C30.6201 16.4365 30.6425 16.3934 30.7318 16.3934H32.1165Z"
                fill="#868786"
              />
              <path
                d="M34.0154 17.6213C33.9484 17.6213 33.9037 17.5782 33.9037 17.4921C33.8814 17.2767 33.7697 17.1689 33.5464 17.1689C33.3007 17.1689 33.1667 17.3628 33.1667 17.6429V18.3107C33.1667 18.3968 33.122 18.4399 33.055 18.4399C32.988 18.4399 32.9434 18.3968 32.9434 18.3107V17.0828C32.9434 16.9966 32.988 16.9535 33.055 16.9535C33.122 16.9535 33.1667 16.9966 33.1667 17.0828V17.1689C33.256 17.0397 33.39 16.9535 33.5911 16.9535C33.8591 16.9535 34.1271 17.1259 34.1271 17.4921C34.1271 17.5998 34.0824 17.6213 34.0154 17.6213Z"
                fill="#868786"
              />
              <path
                d="M35.6904 16.9751C35.9138 16.9751 36.0924 17.0612 36.2041 17.212V17.1259C36.2041 17.0397 36.2488 16.9966 36.3158 16.9966C36.3828 16.9966 36.4274 17.0397 36.4274 17.1259V18.3538C36.4274 18.4399 36.3828 18.483 36.3158 18.483C36.2488 18.483 36.2041 18.4399 36.2041 18.3538V18.2676C36.0924 18.4184 35.9138 18.5045 35.6904 18.5045C35.2884 18.5045 34.998 18.1599 34.998 17.7506C34.998 17.3413 35.2884 16.9751 35.6904 16.9751ZM35.7127 18.2676C36.0254 18.2676 36.2041 18.0306 36.2041 17.729C36.2041 17.4274 36.0031 17.1905 35.6904 17.1905C35.3777 17.1905 35.1991 17.4274 35.1991 17.729C35.1991 18.0306 35.4001 18.2676 35.7127 18.2676Z"
                fill="#868786"
              />
              <path
                d="M37.5215 17.1043C37.5215 17.0181 37.5662 16.9751 37.6332 16.9751C37.7002 16.9751 37.7448 17.0181 37.7448 17.1043V17.1689C37.8342 17.0397 38.0128 16.9535 38.2138 16.9535C38.5935 16.9535 38.7945 17.1905 38.8169 17.5782V18.3107C38.8169 18.3968 38.7722 18.4399 38.7052 18.4399C38.6382 18.4399 38.5935 18.3968 38.5935 18.3107V17.5782C38.5935 17.3197 38.4595 17.1689 38.2138 17.1689C37.9235 17.1689 37.7672 17.3844 37.7672 17.729V18.3322C37.7672 18.4184 37.7225 18.4615 37.6555 18.4615C37.5885 18.4615 37.5438 18.4184 37.5438 18.3322L37.5215 17.1043Z"
                fill="#868786"
              />
              <path
                d="M40.3139 17.1689C40.1576 17.1689 40.0459 17.2551 40.0459 17.3628C40.0459 17.4705 40.1129 17.5352 40.2692 17.5782L40.4926 17.6213C40.7606 17.686 40.9169 17.8152 40.9169 18.0306C40.9169 18.2891 40.6936 18.4615 40.3586 18.4615C40.1129 18.4615 39.9342 18.3753 39.8226 18.2676C39.7556 18.203 39.7779 18.1599 39.8226 18.1168C39.8672 18.0737 39.9119 18.0737 39.9789 18.1168C40.0906 18.203 40.2246 18.2676 40.3586 18.2676C40.5596 18.2676 40.6936 18.1814 40.6936 18.0522C40.6936 17.966 40.6266 17.8798 40.4703 17.8367L40.2469 17.7937C39.9789 17.729 39.8226 17.5998 39.8226 17.3844C39.8226 17.1259 40.0682 16.9751 40.3363 16.9751C40.5149 16.9751 40.6713 17.0182 40.7829 17.1474C40.8499 17.212 40.8499 17.2551 40.8053 17.2982C40.7606 17.3413 40.6936 17.3413 40.6266 17.2767C40.5596 17.2336 40.4703 17.1689 40.3139 17.1689Z"
                fill="#868786"
              />
              <path
                d="M41.9219 17.1043C41.9219 17.0181 41.9665 16.9751 42.0335 16.9751C42.1005 16.9751 42.1452 17.0181 42.1452 17.1043V17.1905C42.2792 17.0397 42.4356 16.9535 42.6589 16.9535C43.0609 16.9535 43.3513 17.2982 43.3513 17.7075C43.3513 18.1168 43.0833 18.4615 42.6589 18.4615C42.4579 18.4615 42.2792 18.3753 42.1676 18.246V18.8923C42.1676 18.9785 42.1229 19.0216 42.0559 19.0216C41.9889 19.0216 41.9442 18.9785 41.9442 18.8923L41.9219 17.1043ZM42.6366 17.1689C42.3462 17.1689 42.1452 17.4059 42.1452 17.7075C42.1452 18.0091 42.3239 18.246 42.6366 18.246C42.9493 18.246 43.1279 18.0091 43.1279 17.7075C43.1056 17.4059 42.9269 17.1474 42.6366 17.1689Z"
                fill="#868786"
              />
              <path
                d="M44.9365 16.9535C45.1598 16.9535 45.3385 17.0397 45.4502 17.1905V17.1043C45.4502 17.0181 45.4949 16.9751 45.5619 16.9751C45.6289 16.9751 45.6735 17.0181 45.6735 17.1043V18.3322C45.6735 18.4184 45.6289 18.4615 45.5619 18.4615C45.4949 18.4615 45.4502 18.4184 45.4502 18.3322V18.246C45.3385 18.3968 45.1598 18.483 44.9365 18.483C44.5345 18.483 44.2441 18.1383 44.2441 17.729C44.2441 17.3197 44.5568 16.9535 44.9365 16.9535ZM44.9588 18.246C45.2715 18.246 45.4502 18.0091 45.4502 17.7075C45.4502 17.4059 45.2492 17.1689 44.9365 17.1689C44.6238 17.1689 44.4451 17.4059 44.4451 17.7075C44.4451 18.0091 44.6685 18.246 44.9588 18.246Z"
                fill="#868786"
              />
              <path
                d="M47.8406 17.5998C47.7736 17.5998 47.7289 17.5567 47.7289 17.4705C47.7066 17.2551 47.5949 17.1474 47.3716 17.1474C47.1259 17.1474 46.9919 17.3413 46.9919 17.6213V18.2891C46.9919 18.3753 46.9472 18.4184 46.8802 18.4184C46.8132 18.4184 46.7686 18.3753 46.7686 18.2891V17.0612C46.7686 16.9751 46.8132 16.932 46.8802 16.932C46.9472 16.932 46.9919 16.9751 46.9919 17.0612V17.1474C47.0812 17.0181 47.2152 16.932 47.4163 16.932C47.6843 16.932 47.9523 17.1043 47.9523 17.4705C47.9523 17.5567 47.9076 17.5998 47.8406 17.5998Z"
                fill="#868786"
              />
              <path
                d="M50.0067 17.7506H49.0463C49.0687 18.0522 49.2473 18.2245 49.5377 18.2245C49.694 18.2245 49.8057 18.1814 49.895 18.0737C49.962 18.0091 50.0067 18.0306 50.0514 18.0737C50.096 18.1168 50.096 18.1599 50.029 18.2245C49.9174 18.3322 49.7387 18.4184 49.5154 18.4184C49.0687 18.4184 48.7783 18.1168 48.7783 17.6859C48.7783 17.2766 49.0687 16.932 49.4707 16.932C49.9397 16.932 50.1184 17.2982 50.1184 17.6213C50.163 17.7075 50.096 17.7506 50.0067 17.7506ZM49.0687 17.5782H49.9397C49.9397 17.3197 49.7834 17.1474 49.5377 17.1689C49.2697 17.1474 49.091 17.3413 49.0687 17.5782Z"
                fill="#868786"
              />
              <path
                d="M51.168 17.0828C51.168 16.9966 51.2126 16.9535 51.2796 16.9535C51.3466 16.9535 51.3913 16.9966 51.3913 17.0828V17.1474C51.4806 17.0181 51.6593 16.932 51.8603 16.932C52.24 16.932 52.441 17.1689 52.4634 17.5567V18.2891C52.4634 18.3753 52.4187 18.4184 52.3517 18.4184C52.2847 18.4184 52.24 18.3753 52.24 18.2891V17.5567C52.24 17.2982 52.106 17.1474 51.8603 17.1474C51.57 17.1474 51.4136 17.3628 51.4136 17.7075V18.3107C51.4136 18.3968 51.369 18.4399 51.302 18.4399C51.235 18.4399 51.1903 18.3968 51.1903 18.3107L51.168 17.0828Z"
                fill="#868786"
              />
              <path
                d="M54.2057 17.1474C53.893 17.1474 53.692 17.3844 53.692 17.6859C53.692 17.9875 53.893 18.2245 54.228 18.2245C54.3843 18.2245 54.496 18.1814 54.5853 18.0737C54.6523 18.0091 54.697 18.0306 54.7417 18.0737C54.7863 18.1168 54.7863 18.1599 54.7193 18.2245C54.6077 18.3322 54.429 18.4184 54.1833 18.4184C53.759 18.4184 53.4463 18.0737 53.4463 17.6644C53.4463 17.2551 53.7813 16.9104 54.2057 16.9104C54.4067 16.9104 54.563 16.9751 54.697 17.0828C54.764 17.1474 54.7417 17.1905 54.7193 17.2336C54.6747 17.2766 54.63 17.2766 54.563 17.2336C54.429 17.1905 54.3397 17.1474 54.2057 17.1474Z"
                fill="#868786"
              />
              <path
                d="M56.8182 17.7506H55.8579C55.8802 18.0522 56.0589 18.2245 56.3492 18.2245C56.5056 18.2245 56.6172 18.1814 56.7066 18.0737C56.7736 18.0091 56.8182 18.0306 56.8629 18.0737C56.9076 18.1168 56.9076 18.1599 56.8406 18.2245C56.7289 18.3322 56.5502 18.4184 56.3269 18.4184C55.8802 18.4184 55.5898 18.1168 55.5898 17.6859C55.5898 17.2766 55.8802 16.932 56.2822 16.932C56.7512 16.932 56.9299 17.2982 56.9299 17.6213C56.9746 17.7075 56.9299 17.7506 56.8182 17.7506ZM55.8802 17.5567H56.7512C56.7512 17.2982 56.5949 17.1259 56.3492 17.1474C56.0812 17.1259 55.9249 17.3197 55.8802 17.5567Z"
                fill="#868786"
              />
              <path
                d="M61.4638 17.5136C61.5531 17.5136 61.5755 17.5567 61.5755 17.5998C61.5755 17.6644 61.5531 17.7075 61.4638 17.7075H59.4984C59.4091 17.7075 59.3867 17.6644 59.3867 17.5998C59.3867 17.5352 59.4091 17.5136 59.4984 17.5136H61.4638Z"
                fill="#868786"
              />
              <path
                d="M64.8142 16.3288C65.2832 16.3288 65.5959 16.5873 65.5959 16.9751C65.5959 17.3628 65.3055 17.6213 64.8142 17.6213H64.3005V18.2676C64.3005 18.3537 64.2558 18.3968 64.1888 18.3968C64.1218 18.3968 64.0771 18.3537 64.0771 18.2676V16.458C64.0771 16.3934 64.1442 16.3288 64.2112 16.3288H64.8142ZM64.3005 16.5442V17.4274H64.8365C65.1492 17.4274 65.3502 17.2766 65.3502 16.9966C65.3502 16.7166 65.1492 16.5658 64.8365 16.5658L64.3005 16.5442Z"
                fill="#868786"
              />
              <path
                d="M67.5614 17.729H66.601C66.6234 18.0306 66.802 18.2029 67.0924 18.2029C67.2487 18.2029 67.3604 18.1599 67.4497 18.0522C67.5167 17.9875 67.5614 18.0091 67.6061 18.0522C67.6507 18.0952 67.6507 18.1383 67.5837 18.2029C67.4721 18.3107 67.2934 18.3968 67.07 18.3968C66.6234 18.3968 66.333 18.0952 66.333 17.6644C66.333 17.2551 66.6234 16.9104 67.0254 16.9104C67.4944 16.9104 67.6731 17.2766 67.6731 17.5998C67.7177 17.6859 67.6731 17.729 67.5614 17.729ZM66.6234 17.5351H67.4944C67.4944 17.2766 67.338 17.1043 67.0924 17.1259C66.8244 17.1259 66.668 17.2982 66.6234 17.5351Z"
                fill="#868786"
              />
              <path
                d="M69.8172 17.5567C69.7502 17.5567 69.7055 17.5136 69.7055 17.4274C69.6832 17.212 69.5715 17.1043 69.3481 17.1043C69.1025 17.1043 68.9685 17.2982 68.9685 17.5782V18.246C68.9685 18.3322 68.9238 18.3753 68.8568 18.3753C68.7898 18.3753 68.7451 18.3322 68.7451 18.246V17.0181C68.7451 16.932 68.7898 16.8889 68.8568 16.8889C68.9238 16.8889 68.9685 16.932 68.9685 17.0181V17.1043C69.0578 16.9751 69.1918 16.8889 69.3928 16.8889C69.6608 16.8889 69.9288 17.0612 69.9288 17.4274C69.9288 17.5351 69.8618 17.5567 69.8172 17.5567Z"
                fill="#868786"
              />
              <path
                d="M71.1574 16.8243V16.932H71.4254C71.5147 16.932 71.5371 16.9751 71.5371 17.0397C71.5371 17.0828 71.5147 17.1474 71.4254 17.1474H71.1574V18.2676C71.1574 18.3537 71.1127 18.3968 71.0457 18.3968C70.9787 18.3968 70.934 18.3537 70.934 18.2676V17.1474H70.7777C70.6883 17.1474 70.666 17.1043 70.666 17.0397C70.666 16.9966 70.6883 16.932 70.7777 16.932H70.934V16.8243C70.934 16.458 71.1127 16.3073 71.4477 16.3073C71.5371 16.3073 71.5594 16.3503 71.5594 16.415C71.5594 16.458 71.5371 16.5227 71.4477 16.5227C71.2244 16.5227 71.1574 16.6304 71.1574 16.8243Z"
                fill="#868786"
              />
              <path
                d="M73.0779 16.8889C73.5022 16.8889 73.8372 17.2336 73.8372 17.6429C73.8372 18.0522 73.5245 18.3968 73.0779 18.3968C72.6535 18.3968 72.3408 18.0522 72.3408 17.6429C72.3408 17.2336 72.6535 16.9104 73.0779 16.8889ZM73.0779 18.2029C73.3905 18.2029 73.5915 17.966 73.5915 17.6644C73.5915 17.3628 73.3682 17.1258 73.0779 17.1258C72.7652 17.1258 72.5642 17.3628 72.5642 17.6644C72.5642 17.966 72.7652 18.2029 73.0779 18.2029Z"
                fill="#868786"
              />
              <path
                d="M75.9148 17.5567C75.8478 17.5567 75.8031 17.5136 75.8031 17.4274C75.7808 17.212 75.6691 17.1043 75.4458 17.1043C75.2001 17.1043 75.0661 17.2982 75.0661 17.5782V18.246C75.0661 18.3322 75.0214 18.3753 74.9544 18.3753C74.8874 18.3753 74.8428 18.3322 74.8428 18.246V17.0181C74.8428 16.932 74.8874 16.8889 74.9544 16.8889C75.0214 16.8889 75.0661 16.932 75.0661 17.0181V17.1043C75.1555 16.9751 75.2895 16.8889 75.4905 16.8889C75.7585 16.8889 76.0265 17.0612 76.0265 17.4274C76.0265 17.5136 75.9818 17.5567 75.9148 17.5567Z"
                fill="#868786"
              />
              <path
                d="M78.8624 17.5136C78.8624 17.2551 78.7507 17.1043 78.5274 17.1043C78.2817 17.1043 78.1477 17.2766 78.1477 17.5782V18.2676C78.1477 18.3537 78.103 18.3968 78.036 18.3968C77.969 18.3968 77.9244 18.3537 77.9244 18.2676V17.5351C77.9244 17.2551 77.8127 17.1043 77.5893 17.1043C77.3437 17.1043 77.2097 17.3197 77.2097 17.5782V18.2676C77.2097 18.3537 77.165 18.3968 77.098 18.3968C77.031 18.3968 76.9863 18.3537 76.9863 18.2676V17.0397C76.9863 16.9535 77.031 16.9104 77.098 16.9104C77.165 16.9104 77.2097 16.9535 77.2097 17.0397V17.0828C77.299 16.9751 77.433 16.8889 77.6117 16.8889C77.8127 16.8889 77.9914 16.9751 78.0584 17.1258C78.17 16.9751 78.3487 16.8889 78.5274 16.8889C78.8847 16.8889 79.0858 17.1258 79.0858 17.5136V18.246C79.0858 18.3322 79.0411 18.3753 78.9741 18.3753C78.9071 18.3753 78.8624 18.3322 78.8624 18.246V17.5136Z"
                fill="#868786"
              />
              <path
                d="M80.7832 16.8889C81.0065 16.8889 81.1852 16.9751 81.2969 17.1258V17.0397C81.2969 16.9535 81.3415 16.9104 81.4085 16.9104C81.4755 16.9104 81.5202 16.9535 81.5202 17.0397V18.2676C81.5202 18.3537 81.4755 18.3968 81.4085 18.3968C81.3415 18.3968 81.2969 18.3537 81.2969 18.2676V18.1814C81.1852 18.3322 81.0065 18.4184 80.7832 18.4184C80.3812 18.4184 80.0908 18.0737 80.0908 17.6644C80.0908 17.2551 80.3812 16.8889 80.7832 16.8889ZM80.8055 18.1814C81.1182 18.1814 81.2969 17.9444 81.2969 17.6429C81.2969 17.3413 81.0959 17.1043 80.7832 17.1043C80.4705 17.1043 80.2918 17.3413 80.2918 17.6429C80.3142 17.9444 80.4928 18.1814 80.8055 18.1814Z"
                fill="#868786"
              />
              <path
                d="M82.6143 17.0181C82.6143 16.932 82.6589 16.8889 82.7259 16.8889C82.7929 16.8889 82.8376 16.932 82.8376 17.0181V17.0828C82.9269 16.9535 83.1056 16.8673 83.3066 16.8673C83.6863 16.8673 83.8873 17.1043 83.9097 17.4921V18.2245C83.9097 18.3106 83.865 18.3537 83.798 18.3537C83.731 18.3537 83.6863 18.3106 83.6863 18.2245V17.4921C83.6863 17.2336 83.5523 17.0828 83.3066 17.0828C83.0163 17.0828 82.8599 17.2982 82.8599 17.6429V18.246C82.8599 18.3322 82.8153 18.3753 82.7483 18.3753C82.6813 18.3753 82.6366 18.3322 82.6366 18.246L82.6143 17.0181Z"
                fill="#868786"
              />
              <path
                d="M85.6295 17.0828C85.3168 17.0828 85.1158 17.3197 85.1158 17.6213C85.1158 17.9229 85.3168 18.1599 85.6518 18.1599C85.8082 18.1599 85.9198 18.1168 86.0092 18.0091C86.0762 17.9444 86.1208 17.966 86.1655 18.0091C86.2102 18.0521 86.2102 18.0952 86.1432 18.1599C86.0315 18.2676 85.8528 18.3537 85.6071 18.3537C85.1828 18.3537 84.8701 18.0091 84.8701 17.5998C84.8701 17.1905 85.2051 16.8458 85.6295 16.8458C85.8305 16.8458 85.9868 16.9104 86.1208 17.0181C86.1878 17.0828 86.1655 17.1258 86.1432 17.1689C86.0985 17.212 86.0538 17.212 85.9868 17.1689C85.8752 17.1258 85.7858 17.0828 85.6295 17.0828Z"
                fill="#868786"
              />
              <path
                d="M88.2655 17.6859H87.3051C87.3275 17.9875 87.5061 18.1599 87.7965 18.1599C87.9528 18.1599 88.0645 18.1168 88.1538 18.0091C88.2208 17.9444 88.2655 17.966 88.3102 18.0091C88.3548 18.0521 88.3548 18.0952 88.2878 18.1599C88.1762 18.2676 87.9975 18.3537 87.7741 18.3537C87.3275 18.3537 87.0371 18.0521 87.0371 17.6213C87.0371 17.212 87.3274 16.8673 87.7295 16.8673C88.1985 16.8673 88.3772 17.2336 88.3772 17.5567C88.4218 17.6428 88.3548 17.6859 88.2655 17.6859ZM87.3275 17.4921H88.1985C88.1985 17.2336 88.0422 17.0612 87.7965 17.0828C87.5285 17.0828 87.3498 17.2551 87.3275 17.4921Z"
                fill="#868786"
              />
              <path
                d="M92.8886 17.4705C92.9779 17.4705 93.0003 17.5136 93.0003 17.5567C93.0003 17.6213 92.9779 17.6644 92.8886 17.6644H90.9232C90.8339 17.6644 90.8115 17.6213 90.8115 17.5567C90.8115 17.4921 90.8339 17.4705 90.9232 17.4705H92.8886Z"
                fill="#868786"
              />
            </g>
            <defs>
              <clipPath id="clip0_1025_1862">
                <rect width="93" height="19" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </div>
    </>
  );
}

export default Logo;
