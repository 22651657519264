import React from "react";
import { Link } from "gatsby";
import "../assets/styles/components/header.scss";
import Logo from "./utils/Logo";
import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import eventBus from "../assets/scripts/utils/eventBus";

function Header({ path }) {
  const [navbar, setNavBar] = React.useState(false);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const [lang, setLang] = React.useState("fr");
  const [pathname, setPathname] = React.useState("");
  const [location, setLocation] = React.useState("/");

  React.useEffect(() => {
    let headerNav = document.querySelector("header");

    if (window.innerWidth > 769) {
      let lastScroll = window.pageYOffset || window.scrollY;
      headerNav.style.top = 0;

      eventBus.on("headerScroll", ({ y, deltaY }) => {
        let currentScroll = window.scrollY || window.scrollY;

        if (
          currentScroll > 20 ||
          window.scrollY > 20 ||
          window.scrollY > 20
        ) {
          if (lastScroll > currentScroll) {
            headerNav.style.top = "0";
          }
          if (lastScroll < currentScroll) {
            headerNav.style.top = "-124px";
          }
        }
        lastScroll = window.scrollY || window.scrollY;

        if (window.scrollY >= 80) {
          setNavBar(true);
        } else {
          setNavBar(false);
        }
      });
    }

    setPathname(window.location.pathname);
    if (window.location.pathname.includes("/en")) {
      setLang("en");
    } else {
      setLang("fr");
    }

    eventBus.on("routeChange", (el) => {
      headerNav.style.top = 0;
      setLocation(el);
    });
  }, []);

  React.useEffect(() => {
    setPathname(window.location.pathname);
    if (window.location.pathname.includes("/en")) {
      setLang("en");
    } else {
      setLang("fr");
    }
  }, [location]);

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  const mobileMenuOpen = () => {
    const BiNavLinks = document.querySelector(".bi-nav-links");
    BiNavLinks.style.marginLeft = 0;
    setMenuIsOpen(true);
    gsap.killTweensOf(BiNavLinks);
    gsap.killTweensOf(BiNavLinks.querySelectorAll("li"));
    let tl = gsap.timeline();
    tl.fromTo(
      BiNavLinks,
      { height: 0 },
      { height: window.innerHeight, duration: 0.6 }
    );
    tl.fromTo(
      BiNavLinks.querySelectorAll("li"),
      { opacity: 0 },
      { opacity: 1, stagger: 0.1 },
      "-=0.3"
    );
  };

  const mobileMenuClose = () => {
    if (window.innerWidth < 769) {
      const BiNavLinks = document.querySelector(".bi-nav-links");
      gsap.killTweensOf(BiNavLinks);
      gsap.killTweensOf(BiNavLinks.querySelectorAll("li"));
      let tl = gsap.timeline({
        onComplete: () => {
          BiNavLinks.style.marginLeft = "-100%";
          setMenuIsOpen(false);
        },
      });
      tl.fromTo(
        BiNavLinks.querySelectorAll("li"),
        { opacity: 1 },
        { opacity: 0 }
      );
      tl.to(BiNavLinks, { height: 0, duration: 0.6 }, "-=0.1");
    } else {
      setMenuIsOpen(false);
    }
  };

  return (
    <>
      <header
        className={
          navbar
            ? "active"
            : "" + path === "/faq" ||
              path === "/contact" ||
              path === "/en/contact" ||
              path === "/en/faq" ||
              path.includes("documents")
            ? "faqColorPage"
            : ""
        }
      >
        <nav className="bi-navbar">
          <Link to={lang === "en" ? "/en" : "/"}>
            <Logo
              menuIsOpen={menuIsOpen}
              color={
                navbar
                  ? "active"
                  : "" + path === "/faq" ||
                    path === "/contact" ||
                    path === "/en/contact" ||
                    path === "/en/faq" ||
                    path.includes("documents")
                  ? "faqColorPage"
                  : ""
              }
            />
          </Link>
          <div className="bi-nav-links">
            <ul>
              <li>
                <Link
                  role="link"
                  onClick={() => mobileMenuClose()}
                  to={(lang === "en" ? "/en" : "") + "/strategie"}
                >
                  {lang === "fr" ? "Stratégie" : "Strategy"}
                </Link>{" "}
              </li>
              <li>
                <Link
                  role="link"
                  onClick={() => mobileMenuClose()}
                  to={(lang === "en" ? "/en" : "") + "/a-propos"}
                >
                  {lang === "fr" ? "À propos" : "About us"}
                </Link>
              </li>
              <li>
                <Link
                  role="link"
                  onClick={() => mobileMenuClose()}
                  to={(lang === "en" ? "/en" : "") + "/comment-souscrire"}
                >
                  {lang === "fr" ? "Comment souscrire ?" : "How to invest ?"}
                </Link>
              </li>
              <li>
                <Link
                  role="link"
                  onClick={() => mobileMenuClose()}
                  to={(lang === "en" ? "/en" : "") + "/contact"}
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  role="link"
                  onClick={() => mobileMenuClose()}
                  to={(lang === "en" ? "/en" : "") + "/faq"}
                  className="faqColorPage"
                >
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  role="link"
                  to={
                    pathname.includes("/en")
                      ? pathname.replace("/en/", "/").replace("/en", "/")
                      : "/en" + pathname
                  }
                >
                  <b>{!pathname.includes("/en") ? "EN" : "FR"}</b>
                </Link>
              </li>
            </ul>
          </div>

          {!menuIsOpen && (
            <div
              className="bi-menu-open"
              role="button"
              tabIndex={0}
              onKeyUp={() => mobileMenuOpen()}
              onClick={() => mobileMenuOpen()}
            >
              <svg
                width="24"
                height="20"
                viewBox="0 0 24 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 20H24V16.6667H0V20ZM0 11.6667H24V8.33333H0V11.6667ZM0 0V3.33333H24V0H0Z"
                  fill="#1C3B60"
                />
              </svg>
            </div>
          )}
          {menuIsOpen && (
            <div
              className="bi-menu-close"
              role="button"
              tabIndex={0}
              onKeyUp={() => mobileMenuClose()}
              onClick={() => mobileMenuClose()}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 2.01429L17.9857 0L10 7.98571L2.01429 0L0 2.01429L7.98571 10L0 17.9857L2.01429 20L10 12.0143L17.9857 20L20 17.9857L12.0143 10L20 2.01429Z"
                  fill="#ffffff"
                ></path>
              </svg>
            </div>
          )}
        </nav>
      </header>
    </>
  );
}

export default Header;
