import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Provider } from "react-redux";
import reduxStore from "../assets/scripts/store/redux.js";
import parallaxAnimation from "../assets/scripts/parallaxAnimation.js";

import "../assets/styles/utils/root.scss";
import AboutCambiumParallaxModal from "./utils/Modals/AboutCambiumParallaxModal";
import AboutCambiumReadMore from "./utils/Modals/AboutCambiumReadMore";

import { Helmet } from "react-helmet";
import eventBus from "../assets/scripts/utils/eventBus";
import PageTransition from "./modules/animations/PageTransition";
import ScreenLoader from "./modules/animations/ScreenLoader";
import useSmoothScroll from "../assets/scripts/utils/hooks/useSmoothScroll";

import scrollLock from "../assets/scripts/utils/scrollLock";

const LayoutPage = ({ children, path }) => {
  const [lang, setLang] = React.useState("fr");
  const scroll = useSmoothScroll();

  React.useEffect(() => {
    scrollLock.disable();
    scroll?.stop();
    setTimeout(() => {
      scroll?.stop();
    }, 100);
    eventBus.on("scrollStop", () => {
      scroll?.stop();
    });
    eventBus.on("scrollStart", () => {
      scroll?.start();
    });

    scroll?.virtualScroll.on(({ y, deltaY }) => {
      eventBus.dispatch("headerScroll", { y, deltaY });
      window.lenis.deltaY = deltaY;
    });
  }, [scroll]);

  React.useEffect(() => {
    window.dispatchEvent(new Event("resize")); // Reload scroll for precision
  }, [children]);

  React.useEffect(() => {
    //apply images's parralax
    eventBus.on('page-loaded', ()=>{
      parallaxAnimation.imagesParallax({ resize: true });
      parallaxAnimation.blockParallax({ resize: true });
    })
  }, []);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "fr",
        }}
      />
      <ScreenLoader />
      <Provider store={reduxStore}>
        <Header path={path} lang={lang} />
        <>
          <div className="bi-modal-portal"></div>
          <AboutCambiumReadMore lang={lang} />
          <AboutCambiumParallaxModal lang={lang} />
        </>
        <PageTransition>
          {children}
        </PageTransition>
        <Footer lang={lang} />
      </Provider>
    </>
  );
};

export default LayoutPage;
