module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cambium Asset Management","short_name":"Cambium","description":"Cambium applique un savoir-faire issu du Private Equity à l’investissement dans les sociétés cotées","start_url":"/","background_color":"#1c3b60","theme_color":"#1c3b60","display":"standalone","orientation":"portrait","icon":"src/images/favicon.svg","icons":[{"src":"icons/regular/icon-path_x48.png","sizes":"48x48","type":"image/png"},{"src":"icons/regular/icon-path_x72.png","sizes":"72x72","type":"image/png"},{"src":"icons/regular/icon-path_x96.png","sizes":"96x96","type":"image/png"},{"src":"icons/regular/icon-path_x144.png","sizes":"144x144","type":"image/png"},{"src":"icons/regular/icon-path_x192.png","sizes":"192x192","type":"image/png"},{"src":"icons/regular/icon-path_x256.png","sizes":"256x256","type":"image/png"},{"src":"icons/regular/icon-path_x384.png","sizes":"384x384","type":"image/png"},{"src":"icons/regular/icon-path_x512.png","sizes":"512x512","type":"image/png"},{"src":"icons/maskable/icon-path_x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"icons/maskable/icon-path_x72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"icons/maskable/icon-path_x96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"icons/maskable/icon-path_x144.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"icons/maskable/icon-path_x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"icons/maskable/icon-path_x256.png","sizes":"256x256","type":"image/png","purpose":"maskable"},{"src":"icons/maskable/icon-path_x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"icons/maskable/icon-path_x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"155913d90b48d25153072da3f02d19de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"],"workboxConfig":{"globPatterns":["**/icon-path*"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
