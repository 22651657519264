import React from "react";

const FormNewsletter = () => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // send request to netlify function login-user
    setLoading(true);
    fetch("/.netlify/functions/subscribe-newsletter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email
      }),
    }).then((res) => {
      if (res.status === 200) {
        setSuccess("Vous êtes bien inscrit");
        setError("");
        setLoading(false);
        res.text().then((data) => {
        });
      } else {
        setSuccess("");
        setError("Sorry vous devez déja être inscrit sur notre list")
        setLoading(false);
      }
    });
  };

  return (
    <form
      className="footer-newsletter-container"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <input
        placeholder="Email"
        className="footer-newsletter__input"
        type="email"
        name="email"
        value={success || error || email}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <button className="footer-newsletter__btn" type="submit">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.0095238 20L20 10L0.0095238 0L0 7.77778L14.2857 10L0 12.2222L0.0095238 20Z"
            fill="#1B1B1D"
          />
        </svg>
      </button>
    </form>
  );
};

export default FormNewsletter;
