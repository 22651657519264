import gsap from "gsap";

const imagesParallax = ({ resize }) => {
  // Select all picture elements
  const initImages = () => {
    const pictures = document.querySelectorAll("picture");
    // Loop through each picture element
    pictures.forEach((picture) => {
      // Get all data attributes
      const dataAttributes = picture.dataset;
      if (
        /*dataAttributes.hasOwnProperty("parallax") &&*/
        dataAttributes.parallax === "true"
      ) {
        gsap.fromTo(
          picture.querySelector("img"),
          {
            scale: 1.2,
            y:
              parseFloat(dataAttributes.pVitesse) *
              (dataAttributes.pDirection === "bottom" ? -100 : 100),
          },
          {
            scale: 1.2,
            y:
              parseFloat(dataAttributes.pVitesse) *
              (dataAttributes.pDirection === "bottom" ? 100 : -100),
            scrollTrigger: {
              trigger: picture.querySelector("img"),
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        );
      }
    });
  };

  if (resize && window.innerWidth > 768) {
    initImages();
  }
  if (!resize) {
    initImages();
  }
};

const blockParallax = ({ resize }) => {
  const initBlocks = () => {
    // select all elements with data-parallax attribute
    const elements = document.querySelectorAll("[data-block-parallax]");
    // remove all picture elements from the array
    elements.forEach((element) => {
      const dataAttributes = element.dataset;
      gsap.fromTo(
        element,
        {
          y:
            parseFloat(dataAttributes.pVitesse) *
            (dataAttributes.pDirection === "bottom"
              ? offsetValueY(element) + -100
              : offsetValueY(element) + 100),
        },
        {
          y:
            parseFloat(dataAttributes.pVitesse) *
            (dataAttributes.pDirection === "bottom"
              ? offsetValueY(element) + 100
              : offsetValueY(element) + -100),
          scrollTrigger: {
            trigger: element,
            start: "bottom center",
            end: "bottom top",
            scrub: true,
          },
        }
      );
    });
  };

  if (resize && window.innerWidth > 768) {
    initBlocks();
  }
  if (!resize) {
    initBlocks();
  }
};

const AboutCambiumParallax = () => {
  // select all elements with data-parallax attribute
  const elements = document.querySelectorAll("[data-text-parallax]");
  // remove all picture elements from the array
  elements.forEach((element) => {
    const dataAttributes = element.dataset;
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".bi-text-paralllax-trigger",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });

    tl.fromTo(
      element,
      {
        x:
          parseFloat(dataAttributes.pVitesse) *
          (dataAttributes.pDirection === "left"
            ? offsetValueX(element) + -100
            : offsetValueX(element) + 100),
        ease: "Power.easeOut",
      },
      {
        x:
          parseFloat(dataAttributes.pVitesse) *
          (dataAttributes.pDirection === "left"
            ? offsetValueX(element) + 100
            : offsetValueX(element) + -100),
        ease: "Power.easeOut",
      }
    );
  });
};
const offsetValueX = (el) => {
  let value = gsap.getProperty(el, "x") ? gsap.getProperty(el, "x") : 0;
  return value;
};
const offsetValueY = (el) => {
  let value = gsap.getProperty(el, "y") ? gsap.getProperty(el, "y") : 0;
  return value;
};

const parallaxAnimation = {
  imagesParallax,
  blockParallax,
  offsetValueX,
  offsetValueY,
  AboutCambiumParallax,
};
export default parallaxAnimation;
