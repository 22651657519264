const React = require("react");
const Layout = require("./src/components/LayoutPage.jsx").default;
const eventBus = require("./src/assets/scripts/utils/eventBus.js").default;

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location !== prevLocation) {
    eventBus.dispatch("routeChange", location);
  }
};

exports.shouldUpdateScroll = () => {
  return false;
};