import React, { useRef } from "react";
import { SwitchTransition, Transition } from "react-transition-group";

import imagesLoaded from "imagesloaded";

import gsap from "gsap";
import eventBus from "../../../assets/scripts/utils/eventBus";

const transitionsData = {
  default: {
    duration: 600,
    onEnter: (node) => {
      gsap.set("main", { opacity: 0 });
      imagesLoaded("main", () => {
        // gsap.set(window, { scrollY: 0 });
        gsap.fromTo(
          "main",
          { opacity: 0 },
          {
            opacity: 1,
            onStart: () => {
              eventBus.dispatch("page-loaded");
            },
            onComplete: () => {
              window.dispatchEvent(new Event("resize"));
            },
          }
        );
      });
    },
    onExit: (node, startLocation) => {
      gsap.fromTo("main", { opacity: 1 }, { opacity: 0 });
    },
  },
};

const PageTransition = ({ children }) => {
  const [startLocation, setStartLocation] = React.useState(0);
  const nodeRef = useRef(null);

  React.useEffect(() => {
    eventBus.on("routeChange", () => {
      setStartLocation(window.scrollY);
    });
  }, []);

  return (
    <>
      <SwitchTransition mode={"out-in"}>
        <Transition
          nodeRef={nodeRef}
          timeout={transitionsData.default.duration}
          key={Math.random()}
          unmountOnExit
          onEnter={(node) => {
            window.scroll(0, 0);
            transitionsData.default.onEnter(node);
          }}
          onExit={(node) => {
            transitionsData.default.onExit(node, startLocation);
          }}
        >
          {(state) => <main ref={nodeRef}>{children}</main>}
        </Transition>
      </SwitchTransition>
    </>
  );
};

export default PageTransition;
